.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* my styling css */

.ViewNot{
  position: absolute;
  transform: translate3d(-112px, 60px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;

}
.showsidebar{
  margin-left: 0 !important;
}
.hidesidebar{
  margin-left: -240px !important;
}
.hide{
  display: none !important;
}
.show{
  display: block !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}


/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .chat-page div, .chat-page section, .chat-page ul {
  height: 100% !important;
} */
.chat-message-cont{
  min-width: 170px !important;
  max-width: 75% !important;
}
.bg-gray{
  background-color: #e9e9e9;
}
.modal-body{
  height: fit-content !important;
}
.group-members-list{
  max-height: 220px;
    overflow-y: auto;
}