@font-face {
    font-family: circularstd;
    font-style: normal;
    font-weight: 400;
    src: local('Circular Std Book'), url(../fonts/CircularStd-Book.woff) format('woff')
}

@font-face {
    font-family: circularstd;
    font-style: italic;
    font-weight: 400;
    src: local('Circular Std Book Italic'), url(../fonts/CircularStd-BookItalic.woff) format('woff')
}

@font-face {
    font-family: circularstd;
    font-style: normal;
    font-weight: 500;
    src: local('Circular Std Medium'), url(../fonts/CircularStd-Medium.woff) format('woff')
}

@font-face {
    font-family: circularstd;
    font-style: italic;
    font-weight: 500;
    src: local('Circular Std Medium Italic'), url(../fonts/CircularStd-MediumItalic.woff) format('woff')
}

@font-face {
    font-family: circularstd;
    font-style: normal;
    font-weight: 600;
    src: local('Circular Std Bold'), url(../fonts/CircularStd-Bold.woff) format('woff')
}

@font-face {
    font-family: circularstd;
    font-style: italic;
    font-weight: 600;
    src: local('Circular Std Bold Italic'), url(../fonts/CircularStd-BoldItalic.woff) format('woff')
}

@font-face {
    font-family: circularstd;
    font-style: normal;
    font-weight: 800;
    src: local('Circular Std Black'), url(../fonts/CircularStd-Black.woff) format('woff')
}

@font-face {
    font-family: circularstd;
    font-style: italic;
    font-weight: 800;
    src: local('Circular Std Black Italic'), url(../fonts/CircularStd-BlackItalic.woff) format('woff')
}

@font-face {
    font-family: material icons;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/MaterialIcons-Regular.eot);
    src: local('Material Icons'), local('MaterialIcons-Regular'), url(../fonts/MaterialIcons-Regular.woff2) format('woff2'), url(../fonts/MaterialIcons-Regular.woff) format('woff'), url(../fonts/MaterialIcons-Regular.ttf) format('truetype')
}

html {
    height: 100%
}

body {
    background-color: #E3E3E3;
    color: #455560;
    font-family: circularstd, sans-serif;
    font-size: .9375rem;
    height: 100%;
    line-height: 1.5;
    overflow-x: hidden
}
.topbar-divider {
    width: 0;
    border-right: 2px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto .5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: circularstd, sans-serif;
    margin-top: 0;
    color: #2c3038
}

a {
    text-decoration: none
}

a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important
}

label {
    margin-bottom: .5rem
}

.form-control {
    border: 1px solid #dee2e6;
    box-shadow: none;
    color: #333;
    padding: .54688rem .875rem;
    font-size: .875rem;
    height: calc(1.6em + 1.21875rem);
    line-height: 1.6;
    font-weight: 400;
    color: #1e2022
}

textarea.form-control {
    height: auto
}

.form-control:focus {
    box-shadow: 0 0 10px rgba(136, 136, 136, .125);
    outline: 0;
    border-color: #c1c9d0
}

.form-control-sm {
    height: calc(1.5em + 1rem);
    padding: .4375rem .65625rem;
    font-size: .8125rem;
    line-height: 1.5;
    border-radius: .25rem
}

.form-control-lg {
    height: calc(1.5em + 1.875rem);
    padding: .875rem 1.09375rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .3125rem
}

a {
    color: #377dff
}

a:hover {
    color: #0052ea;
    text-decoration: none
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

p:last-child {
    margin-bottom: 0
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0
}

input,
button,
a {
    transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -transition: all .4s ease;
    -webkit-transition: all .4s ease
}

input,
input:focus,
button,
button:focus {
    outline: none
}

input[type=file] {
    height: auto;
    min-height: calc(1.5em + .75rem + 2px)
}

input[type=text],
input[type=password] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

textarea.form-control {
    resize: vertical
}

.navbar-nav>li {
    float: left
}

.form-group {
    margin-bottom: 1.25rem
}

.form-group img {
    width: 100%
}

.input-group .form-control {
    height: 40px
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: rgba(0, 0, 0, .1);
    border-color: rgba(0, 0, 0, .1)
}

.material-icons {
    font-family: material icons;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga'
}

ul li {
    list-style: none
}

p:last-child {
    margin-bottom: 0
}

.table {
    color: #333;
    max-width: 100%;
    margin-bottom: 0 !important;
    width: 100%
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
    background-color: #f8f9fa
}

.table.no-border>tbody>tr>td,
.table>tbody>tr>th,
.table.no-border>tfoot>tr>td,
.table.no-border>tfoot>tr>th,
.table.no-border>thead>tr>td,
.table.no-border>thead>tr>th {
    padding: 10px 8px
}

.table-nowrap td,
.table-nowrap th {
    white-space: nowrap
}

.table.dataTable {
    border-collapse: collapse !important
}

table.table td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle
}

table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap
}

.table>tbody>tr>td {
    padding: 10px
}

.table .invoice-link {
    color: #7638ff
}

.table .invoice-link:hover {
    color: #377dff
}

table.table td h2 a {
    color: #333
}

table.table td h2 a:hover {
    color: #7638ff
}

table.table td .items-links {
    color: #1b2559
}

table.table td .items-links:hover {
    color: #7638ff
}

table.table td h2 span {
    color: #888;
    display: block;
    font-size: 12px;
    margin-top: 3px
}

.table thead {
    background-color: transparent;
    border-bottom: 0
}

.table thead tr th {
    font-weight: 500
}

.table.table-center td,
.table.table-center th {
    vertical-align: middle
}

.table-hover tbody tr:hover {
    background-color: #f6f6f7
}

.table td,
.table th {
    vertical-align: middle;
    white-space: nowrap
}

table.table .dropdown-action {
    margin-bottom: 0
}

.table-bordered {
    border: 1px solid rgba(0, 0, 0, .05) !important
}

.table-bordered td:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.table-bordered td:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.table-bordered th,
.table-bordered td {
    border-color: rgba(0, 0, 0, .05)
}

.card-table .card-body {
    padding: 0
}

.card-table .card-body .table>thead>tr>th {
    border-top: 0
}

.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
    padding-left: 1.5rem
}

.card-table .table td,
.card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem .75rem;
    white-space: nowrap;
    align-content: center;
}

.table>:not(:first-child) {
    border: 0
}

table.table td,
table.table th {
    border-color: #dee2e6
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6
}

.table .dropdown-menu {
    width: 200px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0 4px 4px rgba(231, 234, 252, .75);
    border-radius: 6px;
    margin-top: 10px !important
}

.table .dropdown-item {
    font-weight: 500;
    font-size: 14px;
    color: #1b2559;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 14px
}

.table .dropdown-item:last-child {
    margin-bottom: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #7638ff;
    background: 0 0
}

.table .dropdown-item.active,
.table .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #7638ff
}

.table .dropdown-item i {
    font-size: 16px
}

.dataTables_length select {
    appearance: auto
}

.block {
    display: block !important
}

.bullets li {
    list-style: inherit
}

#toggle_btn i {
    font-size: 22px
}

.bg-1 {
    background-color: #ffeec3
}

.bg-1 i {
    color: #ffb800
}

.bg-2 {
    background-color: #d1f3ff
}

.bg-2 i {
    color: #42cdff
}

.bg-3 {
    background-color: #ceffd5
}

.bg-3 i {
    color: #52fb6a
}

.bg-4 {
    background-color: #fccaff
}

.bg-4 i {
    color: #f555ff
}

.bg-5 {
    background-color: #ffb800
}

.bg-6 {
    background-color: #42cdff
}

.bg-7 {
    background-color: #52fb6a
}

.bg-8 {
    background-color: #f555ff
}

.table .btn {
    white-space: nowrap
}

table .badge {
    border-radius: 6px;
    display: inline-block;
    font-size: 14px;
    min-width: 105px;
    padding: 8px 20px;
    font-weight: 500;
    text-align: center
}

.table .bg-success-dark {
    background: #008f64
}

.table .bg-danger-dark {
    background: red
}

.feather {
    width: 18px;
    height: 18px
}

.tableimg img{
    width: 72px;
}

.toggle_btn-two {
    color: #fff
}

.bg-one {
    background-color: #ffb800
}

.bg-one i {
    color: #fff
}

.bg-two {
    background-color: #0ce0ff
}

.bg-two i {
    color: #fff
}

.bg-three {
    background-color: #17d053
}

.bg-three i {
    color: #fff
}

.bg-four {
    background-color: #f555ff
}

.bg-four i {
    color: #fff
}

.btn.focus,
.btn:focus {
    box-shadow: unset
}

.btn-white {
    background-color: #fff;
    border-color: #e7eaf3
}

.btn.btn-rounded {
    border-radius: 50px
}

.bg-primary,
.badge-primary {
    background-color: #7638ff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #621aff !important
}

.bg-success,
.badge-success {
    background-color: #22cc62 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1eae55 !important
}

.bg-info,
.badge-info {
    background-color: #009efb !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #028ee1 !important
}

.bg-warning,
.badge-warning {
    background-color: #ffbc34 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #e9ab2e !important
}

.bg-danger,
.badge-danger {
    background-color: red !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #e63333 !important
}

.bg-white {
    background-color: #fff
}

.bg-purple,
.badge-purple {
    background-color: #9368e9 !important
}

.text-primary,
.dropdown-menu>li>a.text-primary {
    color: #7638ff !important
}

.text-success,
.dropdown-menu>li>a.text-success {
    color: #22cc62 !important
}

.text-danger,
.dropdown-menu>li>a.text-danger {
    color: red !important
}

.text-info,
.dropdown-menu>li>a.text-info {
    color: #009efb !important
}

.text-warning,
.dropdown-menu>li>a.text-warning {
    color: #ffbc34 !important
}

.text-purple,
.dropdown-menu>li>a.text-purple {
    color: #7460ee !important
}

.text-muted {
    color: #757575 !important
}

.btn-primary {
    background-color: #7638ff;
    border: 1px solid #7638ff
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
    background-color: #621aff;
    border: 1px solid #621aff
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    background-color: #621aff;
    border: 1px solid #621aff
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
    background-color: #621aff;
    border-color: #621aff;
    color: #fff
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #7638ff;
    border-color: #7638ff;
    color: #fff
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-success {
    /* background-color: #22cc62;
    border: 1px solid #22cc62 */
    background-color: #298456;
    border: 1px solid #298456
}


.border-dark-new {
    border: 2px solid #909090 ; /* Adjust the thickness and color */
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
    background-color: #1eae55;
    border: 1px solid #1eae55;
    color: #fff
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
    background-color: #1eae55;
    border: 1px solid #1eae55
}

.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show>.btn-success.dropdown-toggle {
    background-color: #1eae55;
    border-color: #1eae55;
    color: #fff
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #22cc62;
    border-color: #22cc62;
    color: #fff
}

.btn-info {
    background-color: #009efb;
    border: 1px solid #009efb
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
    background-color: #028ee1;
    border: 1px solid #028ee1
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
    background-color: #028ee1;
    border: 1px solid #028ee1
}

.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show>.btn-info.dropdown-toggle {
    background-color: #028ee1;
    border-color: #028ee1;
    color: #fff
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff
}

.btn-warning {
    background-color: #ffbc34;
    border: 1px solid #ffbc34
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
    background-color: #e9ab2e;
    border: 1px solid #e9ab2e
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
    background-color: #e9ab2e;
    border: 1px solid #e9ab2e
}

.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle {
    background-color: #e9ab2e;
    border-color: #e9ab2e;
    color: #fff
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff
}

.badge-secondary {
    background-color: #621aff !important
}

.btn-danger {
    background-color: red;
    border: 1px solid red
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
    background-color: #ec1313;
    border: 1px solid #ec1313
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
    background-color: #ec1313;
    border: 1px solid #ec1313
}

.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle {
    background-color: #ec1313;
    border-color: #ec1313;
    color: #fff
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: unset
}

.btn-outline-primary {
    color: #7638ff;
    border-color: #7638ff
}

.btn-outline-primary:hover {
    background-color: #7638ff;
    border-color: #7638ff
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: none
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #7638ff;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: #7638ff;
    border-color: #7638ff
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none
}

.btn-outline-success {
    color: #22cc62;
    border-color: #22cc62
}

.btn-outline-success:hover {
    background-color: #22cc62;
    border-color: #22cc62
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: none
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #22cc62;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    background-color: #22cc62;
    border-color: #22cc62
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: none
}

.btn-outline-info {
    color: #00bcd4;
    border-color: #00bcd4
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: none
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    background-color: transparent;
    color: #00bcd4
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    background-color: #00bcd4;
    border-color: #00bcd4
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: none
}

.btn-outline-warning {
    color: #ffbc34;
    border-color: #ffbc34
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffbc34;
    border-color: #ffbc34
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: none
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    background-color: transparent;
    color: #ffbc34
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffbc34;
    border-color: #ffbc34
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: none
}

.btn-outline-danger {
    color: red;
    border-color: red
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: red;
    border-color: red
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: none
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    background-color: transparent;
    color: red
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    background-color: red;
    border-color: red
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: none
}

.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #ababab
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #7638ff;
    border-color: #7638ff
}

.pagination>li>a,
.pagination>li>span {
    color: #7638ff
}

.page-link:hover {
    color: #7638ff
}

.page-link:focus {
    box-shadow: unset
}

.page-item.active .page-link {
    background-color: #7638ff;
    border-color: #7638ff
}

.dropdown-menu {
    border: 1px solid #eff2f7;
    border-radius: .8rem;
    transform-origin: left top 0;
    background-color: #fff;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08)
}

.navbar-nav .open .dropdown-menu {
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    background-color: #fff
}

.dropdown-menu {
    font-size: 14px
}

.card {
    background: #fff;
    -webkit-box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    margin-bottom: 30px;
    border-radius: 20px;
    display: inline-block;
    width: 100%;
    border: 0
}

.card-body {
    position: relative;
    padding: 1.5rem
}

.card .card-header {
    border-color: #f0f1f5;
    background-color: #fff;
    padding: 1rem;
    border-bottom: 2px solid #949494;
}

.card-header:first-child {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important
}

.card-footer:last-child {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important
}

.card-footer {
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
    padding: 1rem !important;
    border-top: 2px solid #A0CA53 !important;
    /* padding: 0.15rem 1.25rem !important */
}

.table .thead-light th {
    font-size: 16px;
    color: #353535;
    background-color: #E3E3E3;
    border-color: #E3E3E3;
}

.card .card-header .card-title {
    margin-bottom: 0
}

.modal-footer.text-left {
    text-align: left
}

.modal-footer.text-center {
    text-align: center
}

.btn-light {
    border-color: #e6e6e6;
    color: #a6a6a6
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #7638ff;
    text-shadow: unset
}

.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #7638ff
}

.bg-info-light {
    background-color: rgba(2, 182, 179, .12) !important;
    color: #1db9aa !important
}

.bg-primary-light {
    background-color: rgba(17, 148, 247, .12) !important;
    color: #2196f3 !important
}

.bg-danger-light {
    background-color: rgb(255 218 218/49%) !important;
    color: red !important
}

.bg-warning-light {
    background-color: rgba(255, 152, 0, .12) !important;
    color: #f39c12 !important
}

.bg-success-light {
    background-color: rgba(15, 183, 107, .12) !important;
    color: #26af48 !important
}

.bg-purple-light {
    background-color: rgba(197, 128, 255, .12) !important;
    color: #c580ff !important
}

.bg-default-light {
    background-color: rgba(40, 52, 71, .12) !important;
    color: #283447 !important
}

.card-two {
    border: 1px solid #dbdfea;
    border-radius: 5px
}

.badge-soft-primary {
    color: #405189;
    background-color: rgba(64, 81, 137, .1)
}

.badge-soft-secondary {
    color: #3577f1;
    background-color: rgba(53, 119, 241, .1)
}

.badge-soft-success {
    color: #0ab39c;
    background-color: rgba(10, 179, 156, .1)
}

.badge-soft-info {
    color: #299cdb;
    background-color: rgba(41, 156, 219, .1)
}

.badge-soft-warning {
    color: #f7b84b;
    background-color: rgba(247, 184, 75, .1)
}

.badge-soft-danger {
    color: #f06548;
    background-color: rgba(240, 101, 72, .1)
}

.badge-soft-dark {
    color: #212529;
    background-color: rgba(33, 37, 41, .1)
}

.badge-soft-light {
    color: #f3f6f9;
    background-color: rgba(243, 246, 249, .1)
}

.badge-outline-primary {
    color: #405189;
    border: 1px solid #405189;
    background-color: transparent
}

.badge-outline-secondary {
    color: #3577f1;
    border: 1px solid #3577f1;
    background-color: transparent
}

.badge-outline-success {
    color: #0ab39c;
    border: 1px solid #0ab39c;
    background-color: transparent
}

.badge-outline-info {
    color: #299cdb;
    border: 1px solid #299cdb;
    background-color: transparent
}

.badge-outline-warning {
    color: #f7b84b;
    border: 1px solid #f7b84b;
    background-color: transparent
}

.badge-outline-danger {
    color: #f06548;
    border: 1px solid #f06548;
    background-color: transparent
}

.badge-outline-dark {
    color: #212529;
    border: 1px solid #212529;
    background-color: transparent
}

.badge-outline-light {
    color: #f3f6f9;
    border: 1px solid #f3f6f9;
    background-color: transparent
}

.badge-gradient-primary {
    background: linear-gradient(135deg, #405189 0, #0ab39c 100%)
}

.badge-gradient-secondary {
    background: linear-gradient(135deg, #3577f1 0, #299cdb 100%)
}

.badge-gradient-success {
    background: linear-gradient(135deg, #0ab39c 0, #f7b84b 100%)
}

.badge-gradient-danger {
    background: linear-gradient(135deg, #f06548 0, #3577f1 100%)
}

.badge-gradient-warning {
    background: linear-gradient(135deg, #f7b84b 0, #eb990a 100%)
}

.badge-gradient-info {
    background: linear-gradient(135deg, #299cdb 0, #0ab39c 100%)
}

.badge-gradient-dark {
    background: linear-gradient(135deg, #212529 0, #405189 100%)
}

.modal {
    -webkit-overflow-scrolling: touch
}

.modal-footer.text-center {
    justify-content: center
}

.modal-footer.text-left {
    justify-content: flex-start
}

.modal-dialog.modal-md {
    max-width: 600px
}

.custom-modal .modal-content {
    border: 0;
    border-radius: 10px
}

.custom-modal .modal-footer {
    border: 0
}

.custom-modal .modal-header {
    border: 0;
    justify-content: center;
    padding: 30px 30px 0
}

.custom-modal .modal-footer {
    border: 0;
    justify-content: center;
    padding: 0 30px 30px
}

.custom-modal .modal-body {
    padding: 30px
}

.custom-modal .close {
    background-color: #a0a0a0;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    z-index: 99
}

.custom-modal .modal-title {
    font-size: 22px
}

.modal-backdrop.show {
    opacity: .4;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms
}

.modal .card {
    box-shadow: unset
}

.form-header {
    text-align: center;
    margin-bottom: 30px
}

.form-header h3 {
    color: #1b2559;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px
}

.form-header h4 {
    color: #1b2559;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px
}

.form-header p {
    color: #929292;
    font-size: 15px;
    margin-bottom: 0
}

.modal-btn>.row {
    margin-left: -8px;
    margin-right: -8px
}

.modal-btn>.row>div {
    padding-left: 8px;
    padding-right: 8px
}

.paid-continue-btn {
    background-color: #7638ff;
    border: 1px solid #7638ff;
    border-radius: 5px;
    color: #fff;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    text-align: center
}

.paid-continue-btn:hover,
.paid-continue-btn:focus,
.paid-continue-btn:active {
    background-color: #fff;
    border: 1px solid #7638ff;
    color: #7638ff
}

.paid-cancel-btn {
    background-color: #fff;
    border: 1px solid #7638ff;
    border-radius: 5px;
    color: #7638ff;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px;
    text-align: center
}

.paid-cancel-btn:hover,
.paid-cancel-btn:focus,
.paid-cancel-btn:active {
    background-color: #7638ff;
    border: 1px solid #7638ff;
    color: #fff
}

.bank-details .modal-content {
    border: 1px solid #f2ecff;
    border-radius: 6px
}

.bank-details .modal-header {
    border: 0;
    justify-content: space-between;
    padding: 30px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #e5e5e5
}

.bank-details .close {
    background: 0 0;
    border: 0;
    color: #1b2559;
    font-size: 28px;
    line-height: normal;
    top: 20px;
    width: auto;
    height: auto;
    right: 20px
}

.bank-details .close:hover {
    color: #7638ff
}

.bank-details .modal-body {
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5
}

.bank-details .bank-inner-details label {
    font-size: 16px;
    font-weight: 600;
    color: #1b2559
}

.bank-details .bank-inner-details .form-control {
    height: 50px
}

.bank-details .bank-inner-details textarea {
    min-height: 100px
}

.bank-details .bank-inner-details .form-control::placeholder {
    color: #8f9bba
}

.bank-details .modal-footer>* {
    margin: 0
}

.bank-details .modal-footer {
    justify-content: end;
    padding: 30px
}

.bank-details-btn .btn {
    min-width: 160px;
    border-radius: 8px;
    padding: 10px 0;
    color: #fff
}

.bank-details-btn .bank-cancel-btn {
    background: #1b2559
}

.bank-details-btn .bank-cancel-btn:hover {
    background: #7638ff
}

.bank-details-btn .bank-save-btn {
    background: #7638ff
}

.bank-details-btn .bank-save-btn:hover {
    background: #1b2559
}

.select2-container .select2-selection--single {
    border: 1px solid #ddd;
    height: 40px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
    right: 7px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #ddd transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    height: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
    top: 7px
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #ddd;
    border-width: 0 6px 6px
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
    padding-left: 15px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #333;
    font-size: 15px;
    font-weight: 400;
    line-height: 38px
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #7638ff
}

.select2-container--default .select2-selection--multiple {
    border: 1px solid #ddd;
    min-height: 40px
}

.nav-tabs {
    border-bottom: 1px solid #e6e6e6
}

.card-header-tabs {
    border-bottom: 0
}

.nav-tabs>li>a {
    margin-right: 0;
    color: #888;
    border-radius: 0
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
    border-color: transparent;
    color: #333
}

.nav-tabs.nav-tabs-solid>li>a {
    color: #333
}

.nav-tabs.nav-tabs-solid>.active>a,
.nav-tabs.nav-tabs-solid>.active>a:hover,
.nav-tabs.nav-tabs-solid>.active>a:focus {
    background-color: #7638ff;
    border-color: #7638ff;
    color: #fff
}

.tab-content {
    padding-top: 20px
}

.nav-tabs .nav-link {
    border-radius: 0
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    /* background-color: #eee; */
    border-color: transparent;
    color: #333
}

.nav-tabs.nav-justified>li>a {
    border-radius: 0;
    margin-bottom: 0
}

.nav-tabs.nav-justified>li>a:hover,
.nav-tabs.nav-justified>li>a:focus {
    border-bottom-color: #ddd
}

.nav-tabs.nav-justified.nav-tabs-solid>li>a {
    border-color: transparent
}

.nav-tabs.nav-tabs-solid>li>a {
    color: #333;
    padding: 10px 30px
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
    background-color: #7638ff;
    border-color: #7638ff;
    color: #fff;
    padding: 10px 30px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 50px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a {
    border-radius: 50px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded>li>a.active:focus {
    border-radius: 50px
}

.nav-tabs-justified>li>a {
    border-radius: 0;
    margin-bottom: 0
}

.nav-tabs-justified>li>a:hover,
.nav-tabs-justified>li>a:focus {
    border-bottom-color: #ddd
}

.nav-tabs-justified.nav-tabs-solid>li>a {
    border-color: transparent
}

.nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd
}

.nav-tabs.nav-justified.nav-tabs-top>li>a,
.nav-tabs.nav-justified.nav-tabs-top>li>a:hover,
.nav-tabs.nav-justified.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0
}

.nav-tabs.nav-tabs-top>li {
    margin-bottom: 0
}

.nav-tabs.nav-tabs-top>li>a,
.nav-tabs.nav-tabs-top>li>a:hover,
.nav-tabs.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0
}

.nav-tabs.nav-tabs-top>li.open>a,
.nav-tabs.nav-tabs-top>li>a:hover,
.nav-tabs.nav-tabs-top>li>a:focus {
    border-top-color: #ddd
}

.nav-tabs.nav-tabs-top>li+li>a {
    margin-left: 1px
}

.nav-tabs.nav-tabs-top>li>a.active,
.nav-tabs.nav-tabs-top>li>a.active:hover,
.nav-tabs.nav-tabs-top>li>a.active:focus {
    border-top-color: #7638ff
}

.nav-tabs.nav-tabs-bottom>li {
    margin-bottom: -1px
}

.nav-tabs.nav-tabs-bottom>li>a.active,
.nav-tabs.nav-tabs-bottom>li>a.active:hover,
.nav-tabs.nav-tabs-bottom>li>a.active:focus {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #7638ff;
    background-color: transparent;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s
}

.nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0
}

.nav-tabs.nav-tabs-solid>li {
    margin-bottom: 0
}

.nav-tabs.nav-tabs-solid>li>a {
    border-color: transparent
}

.nav-tabs.nav-tabs-solid>li>a:hover,
.nav-tabs.nav-tabs-solid>li>a:focus {
    background-color: #f5f5f5
}

.nav-tabs.nav-tabs-solid>.open:not(.active)>a {
    background-color: #f5f5f5;
    border-color: transparent
}

.nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd
}

.nav-tabs-justified.nav-tabs-top>li>a,
.nav-tabs-justified.nav-tabs-top>li>a:hover,
.nav-tabs-justified.nav-tabs-top>li>a:focus {
    border-width: 2px 0 0
}

.section-header {
    margin-bottom: 1.875rem
}

.section-header .section-title {
    color: #333
}

.line {
    background-color: #7638ff;
    height: 2px;
    margin: 0;
    width: 60px
}

.comp-buttons .btn {
    margin-bottom: 5px
}

.pagination-box .pagination {
    margin-top: 0
}

.comp-dropdowns .btn-group {
    margin-bottom: 5px
}

.progress-example .progress {
    margin-bottom: 1.5rem
}

.progress-xs {
    height: 4px
}

.progress-sm {
    height: 15px
}

.progress.progress-sm {
    height: 6px
}

.progress.progress-md {
    height: 8px
}

.progress.progress-lg {
    height: 18px
}

.row.row-sm {
    margin-left: -3px;
    margin-right: -3px
}

.row.row-sm>div {
    padding-left: 3px;
    padding-right: 3px
}

.avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem
}

.avatar>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar-title {
    width: 100%;
    height: 100%;
    background-color: #7638ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center
}

.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: '';
    border: 2px solid #fff
}

.avatar-online:before {
    background-color: #22cc62
}

.avatar-offline:before {
    background-color: red
}

.avatar-away:before {
    background-color: #ffbc34
}

.avatar .border {
    border-width: 3px !important
}

.avatar .rounded {
    border-radius: 6px !important
}

.avatar .avatar-title {
    font-size: 18px
}

.avatar-xs {
    width: 1.65rem;
    height: 1.65rem
}

.avatar-xs .border {
    border-width: 2px !important
}

.avatar-xs .rounded {
    border-radius: 4px !important
}

.avatar-xs .avatar-title {
    font-size: 12px
}

.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
    border-width: 1px
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem
}

.avatar-sm .border {
    border-width: 3px !important
}

.avatar-sm .rounded {
    border-radius: 4px !important
}

.avatar-sm .avatar-title {
    font-size: 15px
}

.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
    border-width: 2px
}

.avatar-lg {
    width: 3.75rem;
    height: 3.75rem
}

.avatar-lg .border {
    border-width: 3px !important
}

.avatar-lg .rounded {
    border-radius: 8px !important
}

.avatar-lg .avatar-title {
    font-size: 24px
}

.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
    border-width: 3px
}

.avatar-xl {
    width: 5rem;
    height: 5rem
}

.avatar-xl .border {
    border-width: 4px !important
}

.avatar-xl .rounded {
    border-radius: 8px !important
}

.avatar-xl .avatar-title {
    font-size: 28px
}

.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
    border-width: 4px
}

.avatar-xxl {
    width: 5.125rem;
    height: 5.125rem
}

.avatar-xxl .border {
    border-width: 6px !important
}

.avatar-xxl .rounded {
    border-radius: 8px !important
}

.avatar-xxl .avatar-title {
    font-size: 30px
}

.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
    border-width: 4px
}

.avatar-group {
    display: inline-flex
}

.avatar-group .avatar+.avatar {
    margin-left: -.75rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.40625rem
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -.625rem
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -1rem
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -1.28125rem
}

.avatar-group .avatar:hover {
    z-index: 1
}

.header {
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1002;
    height: 60px;
    margin: 10px;
    display: flex;
}

.header .header-left {
    height: 60px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 240px;
    z-index: 1;
    transition: all .2s ease-in-out;
    background-color: #fff;
    border-radius: 15px;
}
.header-title {
    height: 60px;
    margin-top: 20px;
}
.header-title h4{
    font-size: 16px;
    line-height: 0;
    color: #353535;
}
.header-title span{
    color: #353535;
    font-size: 12px;
    font-weight: 500;
}

.header-right{    
    transition: all .2s ease-in-out;
    background-color: #A0CA53;
    border-radius: 15px;
    width: 100%;
    margin-left: 15px;
} 

.header .header-left .logo,
.header .header-left .dark-logo {
    display: inline-block;
    line-height: 60px
}

.header .header-left .logo img {
    max-height: 29px;
    width: auto
}

.header-left .logo.logo-small {
    display: none
}

.header .dropdown-menu>li>a {
    position: relative
}

.header .dropdown-toggle:after {
    display: none
}

.header .has-arrow .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #333;
    border-right: 2px solid #333;
    content: '';
    height: 8px;
    display: inline-block;
    pointer-events: none;
    -webkit-transform-origin: 66% 66%;
    -transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    width: 8px;
    vertical-align: 2px
}

.header .has-arrow .dropdown-toggle[aria-expanded=true]:after {
    -webkit-transform: rotate(-135deg);
    -transform: rotate(-135deg);
    transform: rotate(-135deg)
}

.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
    display: flex !important;
    padding-right: 20px
}

.user-menu.nav>li>a {
    color: #333;
    font-size: 14px;
    line-height: 58px;
    padding: 0 15px;
    height: 60px
}

.user-menu.nav>li>a:hover i,
.user-menu.nav>li>a:focus i {
    opacity: .8
}

.user-img {
    display: inline-block;
    margin-right: 3px;
    position: relative
}

.user-img img {
    width: 40px;
    border-radius: 5%
}

.dropdown-item img {
    margin-right: 5px
}

.user-menu.nav>li>a.mobile_btn {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer
}

.user-menu .dropdown-menu {
    min-width: 200px;
    padding: 0
}

.user-menu .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px 15px
}

.user-menu .dropdown-menu .dropdown-item:hover {
    color: #7638ff;
    background-color: transparent
}

.header .dropdown-menu>li>a:focus,
.header .dropdown-menu>li>a:hover {
    background-color: #7638ff;
    color: #fff
}

.header .dropdown-menu>li>a:focus i,
.header .dropdown-menu>li>a:hover i {
    color: #fff
}

.header .dropdown-menu>li>a {
    padding: 10px 18px
}

.header .dropdown-menu>li>a i {
    color: #7638ff;
    margin-right: 10px;
    text-align: center;
    width: 18px
}

.header .user-menu .dropdown-menu>li>a i {
    color: #7638ff;
    font-size: 16px;
    margin-right: 10px;
    min-width: 18px;
    text-align: center
}

.header .user-menu .dropdown-menu>li>a:focus i,
.header .user-menu .dropdown-menu>li>a:hover i {
    color: #fff
}

.mobile_btn {
    display: none;
    float: left
}

.slide-nav .sidebar {
    margin-left: 0
}

.menu-title {
    color: #a3a3a3;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0 25px
}

.sidebar-overlay {
    background-color: rgba(0, 0, 0, .6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1000
}

.sidebar-overlay.opened {
    display: block
}

html.menu-opened {
    overflow: hidden
}

html.menu-opened body {
    overflow: hidden
}

.header-two {
    border-radius: 0 0 0 0
}

.header-two:before {
    background: #f6f7f8;
    content: "";
    height: 65px;
    position: absolute;
    left: -10px;
    width: 51px;
    margin-left: 231px
}

.mini-sidebar .header-two:before {
    width: 41px
}

.header-three {
    border-radius: 0 0 0 0
}

.header .header-left-two {
    background-color: #101924;
    border-bottom: 1px solid #203247;
    border-radius: 0 16px 0 0
}

.header .header-left-two .logo img,
.header .header-left-two .dark-logo img,
.header .header-left-four .dark-logo img {
    max-height: 45px;
    width: 155px
}

.header .header-left-two a {
    padding: 0 0;
    margin-left: 15px
}

.header .header-left-two a.dark-logo,
.header .header-left-four a.dark-logo {
    display: none
}

.header .header-left-two a.dark-logo.show-logo,
.header .header-left-four a.dark-logo.show-logo {
    display: inline-block
}

.mini-sidebar .header .header-left-two a.dark-logo.show-logo,
.mini-sidebar .header .header-left-four a.dark-logo.show-logo {
    display: none
}

.header .header-left-three {
    width: 66px;
    padding: 0 5px;
    background: #101924
}

.header .header-left-three .logo img {
    max-height: 45px;
    width: 33px
}

.header-four {
    border-radius: 0 0 0 0;
    background-color: #101924
}

.header .header-left-four a {
    padding: 0 0;
    margin-left: 15px
}

.user-menu-four.nav>li>a {
    color: #fff
}

.header .has-arrow .dropdown-toggle-four:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff
}

.header .has-arrow .dropdown-toggle-four:hover:after {
    border-bottom: 2px solid #333;
    border-right: 2px solid #333
}

.user-menu-five.nav>ul>li {
    position: relative;
    border: none
}

.user-menu-five.nav>ul>li>a {
    color: #555a64;
    background-color: #101924
}

.user-menu-five.nav>ul>li>a:hover {
    background-color: #101924;
    border-color: transparent;
    color: #7638ff;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff
}

.nav-tabs-five .nav-item.show .nav-link,
.nav-tabs-five .nav-link.active,
.nav-tabs-five .nav-item.show .nav-link,
.nav-tabs-five .nav-link:hover,
.nav-tabs-five .nav-item.show .nav-link,
.nav-tabs-five .nav-link:focus {
    color: #fff;
    background-color: #101924;
    border-color: #101924 #101924 #101924;
    border-bottom: 4px solid #7638ff
}

.user-menu-five.nav>li>a>svg {
    margin: 0 8px
}

.header .has-arrow .dropdown-toggle-five:after {
    border-bottom: 2px solid #555a64;
    border-right: 2px solid #555a64
}

.header .has-arrow .dropdown-toggle-five:hover:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff
}

.push-submenu>ul {
    padding-left: 15px;
    transition: max-height .2s ease-in-out, visibility .2s ease-in-out
}

.push-submenu>ul .push-link {
    transition: opacity .2s ease-in-out
}

.push-submenu button {
    width: 100%;
    color: #b3b3b1;
    padding: 15px 30px;
    text-align: left;
    background: 0 0;
    border: 0
}

.push-submenu button:hover {
    color: #fff
}

.push-submenu>a,
.push-submenu>button {
    position: relative
}

.push-submenu>a::after,
.push-submenu>button::after {
    content: '';
    display: block;
    height: 11px;
    width: 8px;
    position: absolute;
    top: 50%;
    right: 15px;
    background: url(../img/arrow.svg) no-repeat;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: transform .2s
}

.push-submenu-closed>ul {
    max-height: 0;
    overflow: hidden;
    visibility: hidden
}

.push-submenu-closed .push-link {
    opacity: 0
}

.push-submenu-open>ul {
    max-height: 1000px;
    visibility: visible
}

.push-submenu-open .push-link {
    opacity: 1
}

.push-submenu-open>a::after,
.push-submenu-open>button::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg)
}

.chat-window {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1.875rem;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    border-radius: 20px;
    overflow: hidden
}

.chat-window .chat-cont-left {
    border-right: 1px solid #e5e5e5;
    flex: 0 0 35%;
    left: 0;
    max-width: 35%;
    position: relative;
    z-index: 4
}

.chat-window .chat-cont-left .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    color: #324148;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px
}

.chat-window .chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize
}

.chat-window .chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
    display: inline-flex
}

.chat-window .chat-cont-left .chat-search {
    background-color: #f5f5f6;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%
}

.chat-window .chat-cont-left .chat-search .input-group {
    width: 100%
}

.chat-window .chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px !important;
    padding-left: 36px
}

.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none
}

.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
    align-items: center;
    bottom: 0;
    color: #666;
    display: flex;
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4
}

.chat-window .chat-scroll {
    max-height: calc(100vh - 255px);
    overflow-y: auto
}

.chat-window .chat-cont-left .chat-users-list {
    background-color: #fff
}

.chat-window .chat-cont-left .chat-users-list a.media {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    transition: all .2s ease 0s
}

.chat-window .chat-cont-left .chat-users-list a.media:last-child {
    border-bottom: 0
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
    bottom: 7px;
    height: 10px;
    right: 4px;
    position: absolute;
    width: 10px;
    border: 2px solid #fff
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body {
    display: flex;
    justify-content: space-between;
    width: 100%
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name {
    color: #333;
    text-transform: capitalize
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    color: #8a8a8a;
    font-size: 14px;
    line-height: 24px
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:last-child {
    text-align: right
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px
}

.chat-window .chat-cont-left .chat-users-list a.media:hover {
    background-color: #f5f5f6
}

.chat-window .chat-cont-left .chat-users-list a.media.read-chat .media-body>div:last-child .last-chat-time {
    color: #8a8a8a
}

.chat-window .chat-cont-left .chat-users-list a.media.active {
    background-color: #f5f5f6
}

.chat-window .chat-cont-right {
    flex: 0 0 65%;
    max-width: 65%
}

.chat-window .chat-cont-right .chat-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    height: 72px;
    justify-content: space-between;
    padding: 0 15px
}

.chat-window .chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px
}

.chat-window .chat-cont-right .chat-header .media {
    align-items: center
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 15px
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
    height: 50px;
    width: 50px
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px
}

.chat-window .chat-cont-right .chat-header .chat-options {
    display: flex
}

.chat-window .chat-cont-right .chat-header .chat-options>a {
    align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px
}

.chat-window .chat-cont-right .chat-body {
    background-color: #f5f5f6
}

.chat-window .chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%
}

.chat-window .chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px
}

.chat-window .chat-cont-right .chat-body .media .media-body {
    margin-left: 20px
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box>div {
    padding: 10px 15px;
    border-radius: .25rem;
    display: inline-block;
    position: relative
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box>div p {
    color: #333;
    margin-bottom: 0
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box+.msg-box {
    margin-top: 5px
}

.chat-window .chat-cont-right .chat-body .media.received {
    margin-bottom: 20px
}

.chat-window .chat-cont-right .chat-body .media:last-child {
    margin-bottom: 0
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box>div {
    background-color: #fff
}

.chat-window .chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 0
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div {
    background-color: #e3e3e3
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div p {
    color: #333
}

.chat-window .chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize
}

.chat-window .chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%
}

.chat-window .chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%
}

.chat-window .chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #e0e3e4;
    padding: 10px 15px;
    position: relative
}

.chat-window .chat-cont-right .chat-footer .input-group {
    width: 100%;
    align-items: center
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px !important
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-prepend .btn,
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
    background-color: #7638ff;
    border-color: #7638ff;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px
}

.msg-typing {
    width: auto;
    height: 24px;
    padding-top: 8px
}

.msg-typing span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #a0a0a0;
    display: block;
    border-radius: 50%;
    opacity: .4
}

.msg-typing span:nth-of-type(1) {
    animation: 1s blink infinite .33333s
}

.msg-typing span:nth-of-type(2) {
    animation: 1s blink infinite .66666s
}

.msg-typing span:nth-of-type(3) {
    animation: 1s blink infinite .99999s
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box {
    position: relative
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid #fff;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: -6px;
    position: absolute;
    right: auto;
    top: 8px;
    width: 0
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid #e3e3e3;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -6px;
    top: 8px;
    width: 0
}

.chat-msg-info {
    align-items: center;
    display: flex;
    clear: both;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0
}

.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative
}

.chat-msg-info li:not(:last-child):after {
    position: absolute;
    right: 8px;
    top: 50%;
    content: '';
    height: 4px;
    width: 4px;
    background: #d2dde9;
    border-radius: 50%;
    transform: translate(50%, -50%)
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box>div .chat-time {
    color: rgba(50, 65, 72, .4)
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-time {
    color: rgba(50, 65, 72, .4)
}

.chat-msg-info li a {
    color: #777
}

.chat-msg-info li a:hover {
    color: #2c80ff
}

.chat-seen i {
    color: #00d285;
    font-size: 16px
}

.chat-msg-attachments {
    padding: 4px 0;
    display: flex;
    width: 100%;
    margin: 0 -1px
}

.chat-msg-attachments>div {
    margin: 0 1px
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-info {
    flex-direction: row-reverse
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-attachments {
    flex-direction: row-reverse
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative
}

.chat-attachment img {
    max-width: 100%
}

.chat-attachment {
    position: relative;
    max-width: 130px;
    overflow: hidden
}

.chat-attachment {
    border-radius: .25rem
}

.chat-attachment:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    content: "";
    opacity: .4;
    transition: all .4s
}

.chat-attachment:hover:before {
    opacity: .6
}

.chat-attach-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 7px 15px;
    font-size: 13px;
    opacity: 1;
    transition: all .4s
}

.chat-attach-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .4s;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, .2);
    text-align: center
}

.chat-attach-download:hover {
    color: #495463;
    background: #fff
}

.chat-attachment:hover .chat-attach-caption {
    opacity: 0
}

.chat-attachment:hover .chat-attach-download {
    opacity: 1
}

.chat-attachment-list {
    display: flex;
    margin: -5px
}

.chat-attachment-list li {
    width: 33.33%;
    padding: 5px
}

.chat-attachment-item {
    border: 5px solid rgba(230, 239, 251, .5);
    height: 100%;
    min-height: 60px;
    text-align: center;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div:hover .chat-msg-actions {
    opacity: 1
}

.chat-msg-actions {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all .4s;
    z-index: 2
}

.chat-msg-actions>a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px
}

.chat-msg-actions>a:hover {
    color: #2c80ff
}

@keyframes blink {
    50% {
        opacity: 1
    }
}

.btn-file {
    align-items: center;
    display: inline-flex;
    font-size: 20px;
    justify-content: center;
    overflow: hidden;
    padding: 0 .75rem;
    position: relative;
    vertical-align: middle
}

.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
}

.top-nav-search {
    float: left;
    margin-left: 15px
}

.top-nav-search form {
    margin-top: 10px;
    position: relative;
    width: 230px
}

.top-nav-search .form-control {
    border: 1px solid #e5e5e5;
    border-radius: .5rem;
    color: #333;
    height: 40px;
    padding: 10px 50px 10px 15px;
    box-shadow: rgb(209 243 255/33%) 0 .2px 3px .1px
}

.top-nav-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: #333;
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    right: 0;
    top: 0
}

.top-nav-search.active form {
    display: block;
    left: 0;
    position: absolute
}

.top-nav-search-two {
    margin-left: 60px
}

.top-nav-search-three {
    margin-left: 11px
}

.sidebar {
    background-color: #298456;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 80px;
    transition: all .2s ease-in-out 0s;
    width: 240px;
    z-index: 1001;
    border-radius: 20px;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    overflow-y: hidden;
    margin: 10px;
}

.sidebar.opened {
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease
}

/* .sidebar-inner {
    height: 100%;
    min-height: 100%;
    transition: all .2s ease-in-out 0s
} */

.sidebar-menu ul {
    font-size: 15px;
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
    position: relative;
}

.sidebar-menu li a {
    color: #fff;
    display: block;
    font-size: 15px;
    height: auto;
    padding: 0 20px
}

.sidebar-menu li a:hover {
    color: #fff
}

.sidebar-menu>ul>li>a:hover {
    background-color: #a0ca5321;
    color: #fff
}

.sidebar-menu li.active>a {
    background-color: #a0ca5321;
    color: #fff;
    position: relative
}

.sidebar-menu li.active>a::before {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #A0CA53;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s
}

.menu-title {
    color: #9e9e9e;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap
}

.menu-title>i {
    float: right;
    line-height: 40px
}

.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0
}

.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px
}

.sidebar-menu ul ul a.active {
    color: #fff;
    background-color: #A0CA53;
}

.mobile_btn {
    display: none;
    float: left
}

.sidebar .sidebar-menu>ul>li>a span {
    transition: all .2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap
}

.sidebar .sidebar-menu>ul>li>a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis
}

.sidebar .sidebar-menu>ul>li>a span.badge {
    margin-left: auto
}

.sidebar-menu ul ul a {
    /* display: block; */
    display: flex;
    font-size: 15px;
    padding: 7px 10px 7px 20px;
    position: relative;
    color: #353535;
}

.sidebar-menu ul ul a span {
    float: right
}

.sidebar-menu ul ul {
    display: none;
    background-color: #A0CA53;
    border-radius: 10px;
    margin: 0px 10px;
}

.sidebar-menu ul ul ul a {
    padding-left: 65px
}

.sidebar-menu ul ul ul ul a {
    padding-left: 85px
}

.sidebar-menu>ul>li {
    margin-bottom: 3px;
    position: relative
}

.sidebar-menu>ul>li:last-child {
    margin-bottom: 25px
}

.sidebar-menu .menu-arrow {
    -webkit-transition: -webkit-transform .15s;
    -o-transition: -o-transform .15s;
    transition: transform .15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: "font awesome 5 free";
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 18px;
    top: 11px
}

.sidebar-menu .menu-arrow:before {
    content: "\f105"
}

.sidebar-menu li a.subdrop .menu-arrow {
    -transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}

.sidebar-menu ul ul a .menu-arrow {
    top: 10px
}

.sidebar-menu>ul>li>a {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 8px 15px;
    position: relative;
    transition: all .2s ease-in-out 0s
}

.sidebar-menu>ul>li>a svg {
    width: 18px
}

.sidebar-menu ul li a i {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-align: left;
    vertical-align: middle;
    width: 20px;
    transition: all .2s ease-in-out 0s
}

.sidebar-menu ul li.menu-title a i {
    font-size: 16px !important;
    margin-right: 0;
    text-align: right;
    width: auto
}

.sidebar-menu li a>.badge {
    color: #fff
}

.sidebar-two {
    background-color: #101924;
    top: 60px;
    border-top-right-radius: 0
}

.menu-title-two {
    color: #fff
}

.sidebar-menu-two {
    padding: 20px 0 0
}


.sidebar-menu-two li.active>a {
    background-color: rgb(246 247 249)
}

.sidebar-menu-two li a {
    color: #6e82a5;
    display: block;
    font-size: 15px;
    height: auto;
    padding: 0 20px
}

.sidebar-menu-two>ul>li>a:hover {
    background-color: rgb(247 248 249);
    color: #7638ff
}

.sidebar-three {
    background-color: #fff;
    top: 60px;
    border-top-right-radius: 0;
    width: 306px
}

.sidebar-three-three {
    left: unset
}

.menu-title-three {
    color: #757575
}

.sidebar-menu-three {
    border-bottom: none
}

.sidebar-menu-three>ul {
    border-bottom: none
}

.sidebar-menu-three li.active>a {
    background: rgba(118, 56, 255, .12)
}

.sidebar-menu-three li>a {
    color: #6e82a5;
    display: block;
    font-size: 15px;
    height: auto;
    padding: 9px 7px
}

.sidebar-menu-three>ul>li>a:hover {
    background: rgba(118, 56, 255, .12);
    color: #7638ff
}

.sidebar-menu-three li.active>a::before {
    right: 0;
    left: auto;
    background: #7638ff
}

.sidebar-menu-three ul ul {
    padding: 0
}

.sidebar-menu-three ul ul li a {
    padding-left: 25px;
    display: inline-block
}

.ui-aside-compact .ui-aside {
    margin-left: 0;
    -webkit-transition: all .301s;
    transition: all .301s
}

.ui-aside {
    float: left;
    width: 66px;
    margin-left: 0;
    color: rgba(255, 255, 255, .5);
    -webkit-transition: all .299s;
    transition: all .299s;
    padding: 0;
    margin-right: 11px
}

.ui-aside::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: inherit;
    background-color: inherit;
    border: inherit;
    z-index: -1;
    background: #001621 no-repeat center top;
    background-size: cover
}

.tab {
    border-bottom: none
}

.tab .tablinks {
    display: block;
    background-color: inherit;
    color: #6e82a5;
    padding: 22px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: .3s;
    margin: 0;
    font-size: 17px;
    border: 1px solid #203247
}

.tab .tablinks.active {
    color: #fff;
    background-color: #001621;
    border-color: #182b3e #182b3e #182b3e
}

.tab .tablinks.active:after {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #7638ff;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s
}

.tab .tablinks:hover {
    color: #fff;
    background-color: #101924;
    border-color: #a2212100
}

.tab .tablinks i img {
    width: 16px
}

.tab .tablinks i img:hover {
    filter: invert(1) brightness(100);
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1)
}

.tab .tablinks.active>i img {
    filter: invert(1) brightness(100);
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1)
}

.tab .tablinks .active {
    background-color: #ccc
}

.tab .tablinks .active::before {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #7638ff;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s
}

.tab .tablinks .active::before {
    width: 5px;
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #7638ff;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s
}

.tab-content-three {
    padding-top: 20px;
    margin-left: 65px
}

.sidebar-menu-three .menu-arrow {
    top: auto
}

.sidebar-four {
    background-color: #101924;
    top: 60px;
    border-top-right-radius: 0
}

.sidebar-menu-four>ul>li>a {
    padding: 9px 15px
}

.sidebar-menu-four li a {
    color: #6e82a5;
    display: block;
    font-size: 15px;
    height: auto;
    padding: 0 20px
}

.sidebar-menu-four>ul>li>a:hover {
    background-color: rgb(247 248 249);
    color: #7638ff
}

.menu-title-four {
    color: #fff
}

.sidebar-five {
    background-color: transparent;
    top: 0;
    border-top-right-radius: 0;
    float: left;
    margin: 0;
    position: relative;
    z-index: 99;
    width: auto;
    overflow-y: visible;
    box-shadow: none
}

.sidebar-menu-five ul {
    padding: 10px 0;
    position: relative;
    display: flex
}

.sidebar-menu-five ul .dropdown-menu-right {
    position: absolute;
    width: 220px;
    height: auto;
    border: none
}

.sidebar-menu-five>ul>li {
    margin-bottom: 0;
    position: relative
}

.sidebar-menu-five>ul>li:last-child {
    margin-bottom: 0
}

.sidebar-menu-five li.active>a {
    background-color: rgb(247 248 249);
    color: #7638ff;
    position: relative
}

.sidebar-menu-five ul ul a span {
    transition: all .2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
    float: unset
}

.sidebar-menu-five>ul>li>a {
    color: #fff;
    z-index: 9999
}

.sidebar-menu-five li a:hover {
    color: #7638ff;
    background-color: #fff
}

.sidebar-menu-five>ul>li>a:hover {
    background-color: rgb(16 25 36);
    color: #fff;
    border-bottom: 3px solid #7638ff
}

.sidebar-menu-five .menu-arrow {
    transform: rotate(90deg);
    position: initial
}

.sidebar-menu-five li a.subdrop .menu-arrow {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.sidebar-menu-five ul ul a:hover {
    background-color: rgba(118, 56, 255, .05);
    color: #7638ff
}

.header .dropdown-menu-five>li>a:focus,
.header .dropdown-menu-five>li>a:hover {
    background-color: rgb(246 247 249);
    color: #7638ff
}

.header .nav-tabs {
    border: 0
}

.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px
}

.calendar-events:hover {
    border-color: #e9e9e9;
    background-color: #fff
}

.calendar-events i {
    margin-right: 8px
}

.calendar {
    float: left;
    margin-bottom: 0
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem
}

.none-border .modal-footer {
    border-top: none
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: roboto, sans-serif;
    line-height: 30px;
    text-transform: uppercase
}

.fc-day-grid-event .fc-time {
    font-family: roboto, sans-serif
}

.fc-day {
    background: #fff
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
    z-index: 0
}

.fc th.fc-widget-header {
    background: #eee;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
    border-color: #f3f3f3
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
    padding: 2px 5px
}

.fc-button {
    background: #f1f1f1;
    border: none;
    color: #797979;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px
}

.fc-state-hover {
    background: #f3f3f3
}

.fc-state-highlight {
    background: #f0f0f0
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #7638ff !important;
    color: #fff !important;
    text-shadow: none !important
}

.fc-cell-overlay {
    background: #f0f0f0
}

.fc-unthemed .fc-today {
    background: #fff
}

.fc-event {
    border-radius: 2px;
    border: none;
    color: #fff !important;
    cursor: move;
    font-size: 13px;
    margin: 1px 7px;
    padding: 5px;
    text-align: center
}

.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: roboto, sans-serif
}

.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: roboto, sans-serif
}

.event-form .input-group .form-control {
    height: 40px
}

.submit-section {
    text-align: center;
    margin-top: 40px
}

.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px
}

.dropdown-action {
    margin-bottom: 5px
}

.dropdown-action .dropdown-toggle:after {
    display: none
}

.action-icon {
    color: #333;
    font-size: 18px;
    display: inline-block
}

.table-inbox input[type=radio],
.table-inbox input[type=checkbox] {
    cursor: pointer
}

.mail-list {
    list-style: none;
    padding: 0
}

.mail-list>li>a {
    color: #333;
    display: block;
    padding: 10px
}

.mail-list>li.active>a {
    color: #7638ff;
    font-weight: 700
}

.unread .name,
.unread .subject,
.unread .mail-date {
    color: #000;
    font-weight: 600
}

.table-inbox .starred.fas.fa-star {
    color: #ffd200
}

.table.table-inbox>tbody>tr>td,
.table.table-inbox>tbody>tr>th,
.table.table-inbox>tfoot>tr>td,
.table.table-inbox>tfoot>tr>th,
.table.table-inbox>thead>tr>td,
.table.table-inbox>thead>tr>th {
    border-bottom: 1px solid #f2f2f2;
    border-top: 0
}

.table-inbox {
    font-size: 15px;
    margin-bottom: 0
}

.table.table-inbox thead {
    background-color: #fff
}

.note-editor.note-frame {
    border: 1px solid #ddd;
    box-shadow: inherit
}

.note-editor.note-frame .note-statusbar {
    background-color: #fff
}

.note-editor.note-frame.fullscreen {
    top: 60px
}

.note-editor.note-frame .btn-light {
    background-color: #f9f9f9;
    box-shadow: unset;
    color: #333
}

.mail-title {
    font-weight: 700;
    text-transform: uppercase
}

.form-control.search-message {
    border-color: #ccc;
    border-radius: 4px;
    height: 38px;
    width: 180px
}

.table-inbox tr {
    cursor: pointer
}

table.table-inbox tbody tr.checked {
    background-color: #ffc
}

.mail-label {
    font-size: 16px !important;
    margin-right: 5px
}

.attachments {
    list-style: none;
    margin: 0;
    padding: 0
}

.attachments li {
    border: 1px solid #eee;
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 180px
}

.attach-info {
    background-color: #f4f4f4;
    padding: 10px
}

.attach-file {
    color: #777;
    font-size: 70px;
    padding: 10px;
    min-height: 138px;
    display: flex;
    align-items: center;
    justify-content: center
}

.attach-file img {
    height: auto;
    max-width: 100%
}

.mailview-header {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 15px
}

.mailview-footer {
    border-top: 1px solid #ddd;
    margin-top: 20px;
    padding-top: 15px
}

.mailview-footer .btn-white {
    margin-top: 10px;
    min-width: 102px
}

.sender-img {
    float: left;
    margin-right: 10px;
    width: 40px
}

.sender-name {
    display: block
}

.receiver-name {
    color: #777
}

.mail-view-title {
    font-weight: 500;
    font-size: 24px;
    margin: 0
}

.mail-view-action {
    float: right
}

.mail-sent-time {
    float: right
}

.inbox-menu {
    display: inline-block;
    margin: 0 0 1.875rem;
    padding: 0;
    width: 100%
}

.inbox-menu li {
    display: inline-block;
    width: 100%
}

.inbox-menu li+li {
    margin-top: 2px
}

.inbox-menu li a {
    color: #333;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    text-transform: capitalize;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    transition: .3s ease
}

.inbox-menu li a i {
    font-size: 16px;
    padding-right: 10px;
    color: #878787
}

.inbox-menu li a:hover,
.inbox-menu li.active a,
.inbox-menu li a:focus {
    background: rgba(33, 33, 33, .05)
}

.compose-btn {
    margin-bottom: 1.875rem
}

.compose-btn a {
    font-weight: 600;
    padding: 8px 15px
}

.error-page {
    align-items: center;
    color: #1f1f1f;
    display: flex
}

.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
    min-height: unset
}

.error-box {
    margin: 0 auto;
    max-width: 480px;
    padding: 1.875rem 0;
    text-align: center;
    width: 100%
}

.error-box h1 {
    color: #7638ff;
    font-size: 10em
}

.error-box p {
    margin-bottom: 1.875rem
}

.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px
}

.main-wrapper {
    width: 100%;
    height: 100vh;
    min-height: 100vh
}

.page-wrapper {
    margin-left: 240px;
    padding-top: 60px;
    position: relative;
    transition: all .4s ease
}

.page-wrapper>.content {
    padding: 1.875rem 1.875rem 0
}

.page-header {
    margin-bottom: 1.875rem
}

.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0
}

.page-header .breadcrumb a {
    color: #333
}

.page-title {
    color: #333;
    margin-bottom: 5px
}

.page-wrapper-one {
    padding-top: 60px
}

.page-wrapper-two {
    margin-left: 265px;
    padding-top: 0;
    min-height: 70px
}

.page-wrapper-three {
    margin-left: 315px;
    padding-top: 80px;
    position: relative;
    transition: all .4s ease
}

.page-wrapper-four {
    margin-left: 0;
    padding-top: 60px;
    position: relative;
    transition: all .4s ease
}

.login-body {
    display: table;
    height: 100vh;
    min-height: 100vh
}

.logo-dark {
    max-width: 180px;
    margin: auto;
    display: block
}

.login-wrapper {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-top: 1.875rem
}

.login-wrapper .loginbox {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    display: flex;
    margin: 1.875rem auto;
    max-width: 450px;
    width: 100%
}

.login-wrapper .loginbox .login-left {
    align-items: center;
    background: linear-gradient(180deg, #8BC34A, #00bcd4);
    border-radius: 6px 0 0 6px;
    flex-direction: column;
    justify-content: center;
    padding: 80px;
    width: 450px;
    display: flex
}

.login-wrapper .loginbox .login-right {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2rem;
    width: 450px
}

.login-wrapper .loginbox .login-right .login-right-wrap {
    max-width: 100%;
    flex: 0 0 100%
}

.login-wrapper .loginbox .login-right h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center
}

.account-subtitle {
    color: #4c4c4c;
    font-size: 17px;
    margin-bottom: 1.875rem;
    text-align: center
}

.login-wrapper .loginbox .login-right .forgotpass a {
    color: #a0a0a0
}

.login-wrapper .loginbox .login-right .forgotpass a:hover {
    color: #333;
    text-decoration: underline
}

.login-wrapper .loginbox .login-right .dont-have {
    color: #a0a0a0;
    margin-top: 1.875rem
}

.login-wrapper .loginbox .login-right .dont-have a {
    color: #333
}

.login-wrapper .loginbox .login-right .dont-have a:hover {
    text-decoration: underline
}

.social-login {
    text-align: center
}

.social-login>span {
    color: #757575;
    margin-right: 8px
}

.social-login>a {
    background-color: #ccc;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    margin-right: 6px;
    text-align: center;
    width: 32px
}

.social-login>a:last-child {
    margin-right: 0
}

.social-login>a.facebook {
    background-color: #4b75bd
}

.social-login>a.google {
    background-color: #fe5240
}

.login-or {
    color: #757575;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative
}

.or-line {
    background-color: #e5e5e5;
    height: 1px;
    margin-bottom: 0;
    margin-top: 0;
    display: block
}

.span-or {
    background-color: #fff;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 42px
}

.lock-user {
    margin-bottom: 20px;
    text-align: center
}

.lock-user img {
    margin-bottom: 15px;
    width: 100px
}

.toggle-password {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: #757575;
    cursor: pointer
}

.toggle-password.fa-eye {
    margin-right: 1px
}

.pass-group {
    position: relative
}

.notifications {
    padding: 0
}

.notifications .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: #bdbdbd
}

.notifications .media {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5
}

.notifications .media:last-child {
    border-bottom: none
}

.notifications .media a {
    display: block;
    padding: 10px 15px;
    border-radius: 2px
}

.notifications .media a:hover {
    background-color: #fafafa
}

.notifications .media>.avatar {
    margin-right: 10px
}

.notifications .media-list .media-left {
    padding-right: 8px
}

.topnav-dropdown-header {
    border-bottom: 1px solid #eee;
    text-align: center
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px
}

.topnav-dropdown-footer {
    border-top: 1px solid #eee
}

.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #333
}

.display-none {
    display: none
}

.user-menu.nav>li>a .badge {
    background-color: red;
    display: block;
    font-size: 10px;
    font-weight: 700;
    min-height: 15px;
    min-width: 15px;
    color: #fff;
    position: absolute;
    right: 3px;
    top: 6px
}

.user-menu.nav>li>a>i {
    font-size: 1.5rem;
    line-height: 60px
}

.user-menu.nav>li>a>svg {
    font-size: 1.5rem;
    line-height: 60px
}

.noti-details {
    color: #989c9e;
    margin-bottom: 0
}

.noti-title {
    color: #333
}

.notifications .noti-content {
    height: 290px;
    width: 350px;
    overflow-y: auto;
    position: relative
}

.notification-list {
    list-style: none;
    padding: 0;
    margin: 0
}

.notifications ul.notification-list>li {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5
}

.notifications ul.notification-list>li:last-child {
    border-bottom: none
}

.notifications ul.notification-list>li a {
    display: block;
    padding: 10px 15px;
    border-radius: 2px
}

.notifications ul.notification-list>li a:hover {
    background-color: #fafafa
}

.notifications ul.notification-list>li .list-item {
    border: 0;
    padding: 0;
    position: relative
}

.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px
}

.topnav-dropdown-header .clear-noti {
    color: #f83f37;
    float: right;
    font-size: 12px;
    text-transform: uppercase
}

.noti-time {
    margin: 0
}

.user-menu-four {
    border-bottom: none
}

.user-menu-five {
    border-bottom: none;
    float: left
}

.user-menu-five.nav>li>a {
    color: #555a64;
    height: 59px
}

.dash-title {
    font-size: 18px;
    font-weight: 500;
    color: #353535;
}

.dash-widget-icon {
    align-items: center;
    border-radius: 10px;
    color: #fff;
    display: inline-flex;
    font-size: 1.875rem;
    height: 4rem;
    justify-content: center;
    text-align: center;
    width: 4rem
}

.dash-count {
    font-size: 18px;
}

.dash-widget-info h3 {
    margin-bottom: 10px
}

.dash-widget-header {
    align-items: center;
    display: flex
}

.activity-feed {
    list-style: none;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 0
}

.activity-feed .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 19px;
    padding-left: 20px;
    position: relative
}

.activity-feed .feed-item:last-child {
    border-color: transparent
}

.activity-feed .feed-item:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #7638ff
}

.activity-feed .feed-item .feed-date {
    display: block;
    position: relative;
    color: #777;
    text-transform: uppercase;
    font-size: 13px
}

.activity-feed .feed-item .feed-text {
    color: #777;
    position: relative
}

.activity-feed .feed-item .feed-text a {
    color: #333;
    font-weight: 600
}

.modal-icon i {
    font-size: 42px;
    color: #6c757d
}

.modal-footer {
    border-top: 0;
    padding-top: 0;
    padding-bottom: .75rem
}

.add-remove i {
    font-size: 18px;
    cursor: pointer
}

.invoice-item .invoice-logo {
    margin-bottom: 30px
}

.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px
}

.invoice-item .invoice-text h2 {
    color: #272b41;
    font-size: 36px;
    font-weight: 600
}

.invoice-item .invoice-details {
    text-align: right;
    font-weight: 400
}

.invoice-item .invoice-details strong {
    color: #272b41
}

.invoice-item .invoice-details-two {
    text-align: left;
    color: #000
}

.invoice-item .invoice-text {
    padding-top: 42px;
    padding-bottom: 36px
}

.invoice-item .invoice-text h2 {
    font-weight: 400
}

.invoice-info {
    margin-bottom: 20px
}

.invoice-info p {
    margin-bottom: 0
}

.invoice-info.invoice-info2 {
    text-align: right
}

.invoice-item .customer-text {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 16px;
    display: block
}

.invoice-item .customer-text a {
    font-size: 14px;
    color: #7638ff;
    margin-left: 12px
}

.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
    color: #272b41;
    font-weight: 600;
    padding: 15px 20px;
    line-height: inherit
}

.invoice-table tr td,
.invoice-table-two tr td {
    font-weight: 500
}

.invoice-table-two {
    margin-bottom: 0
}

.invoice-table-two tr th,
.invoice-table-two tr td {
    border-top: 0
}

.invoice-table-two tr td {
    text-align: right
}

.invoice-info h5 {
    font-size: 16px;
    font-weight: 500
}

.other-info {
    margin-top: 10px
}

.file {
    visibility: hidden;
    position: absolute
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.font-size-14 {
    font-size: 14px
}

.settings-menu {
    margin-bottom: 30px
}

.settings-menu ul {
    display: block;
    padding: 0
}

.settings-menu ul li+li {
    margin-top: 15px
}

.settings-menu ul li a {
    color: #1b2559;
    padding: 0;
    border: 0 !important;
    display: inline-block
}

.settings-menu ul li a:hover,
.settings-menu ul li a:focus {
    background-color: transparent !important;
    border: 0 !important
}

.settings-menu ul li a.active {
    color: #7638ff !important;
    border: 0
}

.settings-menu ul li i {
    margin-right: 10px;
    font-size: 20px;
    min-width: 25px
}

.toggle-switch {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer
}

.toggle-switch:not(.form-group) {
    margin-bottom: 0
}

.toggle-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.toggle-switch-content {
    -ms-flex: 1;
    flex: 1;
    margin-left: .5rem
}

.toggle-switch-label {
    position: relative;
    display: block;
    width: 3rem;
    height: 2rem;
    background-color: #e7eaf3;
    background-clip: content-box;
    border: .125rem solid transparent;
    border-radius: 6.1875rem;
    transition: .3s
}

.toggle-switch-indicator {
    position: absolute;
    left: .125rem;
    bottom: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    -webkit-transform: initial;
    transform: initial;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25);
    border-radius: 50%;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
    transition: .3s
}

.toggle-switch-input:checked+.toggle-switch-label {
    background-color: #7638ff
}

.toggle-switch-input:checked+.toggle-switch-label .toggle-switch-indicator {
    -webkit-transform: translate3d(1.025rem, 50%, 0);
    transform: translate3d(1.025rem, 50%, 0)
}

.toggle-switch-input.is-valid+.toggle-switch-label {
    background-color: #00c9a7
}

.toggle-switch-input.is-invalid+.toggle-switch-label {
    background-color: #ed4c78
}

.toggle-switch-input:disabled+.toggle-switch-label {
    background-color: rgba(231, 234, 243, .5)
}

.toggle-switch-input:checked:disabled+.toggle-switch-label {
    background-color: rgba(55, 125, 255, .5)
}

.toggle-switch-sm .toggle-switch-label {
    width: 2.5rem;
    height: 1.6125rem
}

.toggle-switch-sm .toggle-switch-indicator {
    width: 1.20938rem;
    height: 1.20938rem
}

.toggle-switch-sm .toggle-switch-input:checked+.toggle-switch-label .toggle-switch-indicator {
    -webkit-transform: translate3d(.81094rem, 50%, 0);
    transform: translate3d(.81094rem, 50%, 0)
}

.cal-icon {
    position: relative;
    width: 100%
}

.cal-icon:after {
    color: #979797;
    content: "\f073";
    display: block;
    font-family: "font awesome 5 free";
    font-weight: 900;
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px
}

.profile-cover {
    position: relative;
    padding: 1.75rem 2rem;
    border-radius: .75rem;
    height: 10rem
}

.profile-cover-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 10rem;
    background-color: #e7eaf3;
    border-radius: .75rem
}

.profile-cover-img {
    width: 100%;
    height: 10rem;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: top;
    border-radius: .75rem
}

.cover-content {
    position: relative;
    z-index: 1;
    padding: 1rem 2rem;
    position: absolute;
    bottom: 0;
    right: 0
}

.custom-file-btn {
    position: relative;
    overflow: hidden
}

.custom-file-btn-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0
}

.profile-cover-avatar {
    display: -ms-flexbox;
    display: flex;
    margin: -6.3rem auto .5rem;
    border: 3px solid #fff;
    border-radius: 50%
}

.profile-cover-avatar input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 33, 68, .25);
    border-radius: 50%;
    transition: .2s
}

.avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #677788;
    background-color: #fff;
    border-radius: 50%;
    transition: .2s;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25)
}

.avatar-edit svg {
    width: 18px
}

.card-table div.table-responsive>div.dataTables_wrapper>div.row:first-child {
    padding: 1.5rem 1.5rem 0
}

.card-table div.table-responsive>div.dataTables_wrapper>div.row:last-child {
    padding: 0 1.5rem 1.5rem
}

div.dataTables_wrapper div.dataTables_filter label {
    margin-bottom: 0;
    position: relative
}

div.dataTables_wrapper div.dataTables_filter label i {
    position: absolute;
    top: 13px;
    left: 10px;
    font-size: 14px
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0;
    display: inline-block;
    width: auto;
    padding-left: 30px;
    border: 1px solid #e5e5e5;
    color: #1b2559;
    height: 40px;
    border-radius: 8px
}

#filter_inputs {
    display: none
}

.input-label {
    display: block;
    color: #1e2022;
    font-size: .875rem
}

.submit-section {
    text-align: center;
    margin-top: 40px
}

.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px
}

.card-body-height {
    height: 28rem;
    overflow: hidden;
    overflow-y: auto
}

.profile-cover-avatar .avatar-img {
    display: block;
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%
}

.draggable {
    cursor: -webkit-grab;
    cursor: grab
}

.gu-unselectable .draggable {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

#dd-handle .list-group,
#multiple-list-group-a,
#multiple-list-group-b {
    min-height: 5.714rem
}

#dd-handle .list-group .handle,
.gu-mirror .handle {
    padding: 0 5px;
    margin-right: 5px;
    background-color: rgba(0, 0, 0, .1);
    cursor: move;
    font-size: 1.2rem
}

.custom-file-container {
    box-sizing: border-box;
    position: relative;
    display: block
}

.custom-file-container label {
    color: #4361ee
}

.custom-file-container__custom-file {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    margin-top: 5px
}

.custom-file-container__custom-file:hover {
    cursor: pointer
}

.custom-file-container__custom-file__custom-file-input {
    box-sizing: border-box;
    min-width: 14rem;
    max-width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-container__custom-file__custom-file-input:focus~span {
    outline: 1px dotted #515365;
    outline: 5px auto -webkit-focus-ring-color
}

.custom-file-container__custom-file__custom-file-control {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: auto;
    overflow: hidden;
    line-height: 1.5;
    user-select: none;
    background-clip: padding-box;
    border-radius: .25rem;
    height: auto;
    border: 1px solid #f1f2f3;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #f1f2f3
}

.custom-file-container__custom-file__custom-file-control__button {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    display: block;
    height: auto;
    padding: 10px 16px;
    line-height: 1.25;
    background-color: rgba(27, 85, 226, .23921568627450981);
    color: #4361ee;
    border-left: 1px solid #e0e6ed;
    box-sizing: border-box
}

.custom-file-container__image-preview {
    box-sizing: border-box;
    transition: all .2s ease;
    margin-top: 54px;
    margin-bottom: 40px;
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: auto;
    padding: 15px
}

.custom-file-container__image-multi-preview {
    position: relative;
    box-sizing: border-box;
    transition: all .2s ease;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
    margin: 1.858736%;
    width: 29.615861214%;
    height: 90px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, .25)
}

.custom-file-container__image-multi-preview__single-image-clear {
    left: -6px;
    background: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-top: -6px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, .25)
}

.custom-file-container__image-multi-preview__single-image-clear:hover {
    background: #cbcbbd;
    cursor: pointer
}

.custom-file-container__image-multi-preview__single-image-clear__icon {
    color: #4361ee;
    display: block;
    margin-top: -2px
}

.note-editor.card {
    padding: .5rem
}

.note-toolbar.card-header {
    padding: 0;
    padding-bottom: .5rem
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child,
.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
    background-color: #7638ff
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #7638ff
}

.ribbon-primary {
    background: #7366ff
}

.ribbon-bookmark.ribbon-primary:before {
    border-color: #7366ff;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-bottom-color: transparent
}

.ribbon-primary.ribbon-corner:before {
    border-top-color: #7366ff;
    border-left-color: #7366ff
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
    border-right-color: #7366ff;
    border-left-color: transparent
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #7366ff
}

.ribbon-primary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #43f;
    border-right-color: #43f
}

.ribbon-primary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #43f;
    border-left-color: #43f
}

.ribbon-primary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: #43f
}

.ribbon-primary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: transparent;
    border-left-color: #43f
}

.ribbon-secondary {
    background: #f73164
}

.ribbon-secondary {
    background: #f73164
}

.ribbon-bookmark.ribbon-secondary:before {
    border-color: #f73164;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-bottom-color: transparent
}

.ribbon-secondary.ribbon-corner:before {
    border-top-color: #f73164;
    border-left-color: #f73164
}

.ribbon-secondary.ribbon-corner.ribbon-right:before {
    border-right-color: #f73164;
    border-left-color: transparent
}

.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f73164
}

.ribbon-secondary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #ec0944;
    border-right-color: #ec0944
}

.ribbon-secondary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #ec0944;
    border-left-color: #ec0944
}

.ribbon-secondary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: #ec0944
}

.ribbon-secondary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: transparent;
    border-left-color: #ec0944
}

.ribbon-success {
    background: #51bb25
}

.ribbon-success {
    background: #51bb25
}

.ribbon-bookmark.ribbon-success:before {
    border-color: #51bb25;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-bottom-color: transparent
}

.ribbon-success.ribbon-corner:before {
    border-top-color: #51bb25;
    border-left-color: #51bb25
}

.ribbon-success.ribbon-corner.ribbon-right:before {
    border-right-color: #51bb25;
    border-left-color: transparent
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #51bb25
}

.ribbon-success.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #3f901d;
    border-right-color: #3f901d
}

.ribbon-success.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #3f901d;
    border-left-color: #3f901d
}

.ribbon-success.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: #3f901d
}

.ribbon-success.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: transparent;
    border-left-color: #3f901d
}

.ribbon-danger {
    background: #dc3545
}

.ribbon-danger {
    background: #dc3545
}

.ribbon-bookmark.ribbon-danger:before {
    border-color: #dc3545;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-bottom-color: transparent
}

.ribbon-danger.ribbon-corner:before {
    border-top-color: #dc3545;
    border-left-color: #dc3545
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
    border-right-color: #dc3545;
    border-left-color: transparent
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dc3545
}

.ribbon-danger.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #bd2130;
    border-right-color: #bd2130
}

.ribbon-danger.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #bd2130;
    border-left-color: #bd2130
}

.ribbon-danger.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: #bd2130
}

.ribbon-danger.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: transparent;
    border-left-color: #bd2130
}

.ribbon-info {
    background: #a927f9
}

.ribbon-info {
    background: #a927f9
}

.ribbon-bookmark.ribbon-info:before {
    border-color: #a927f9;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-bottom-color: transparent
}

.ribbon-info.ribbon-corner:before {
    border-top-color: #a927f9;
    border-left-color: #a927f9
}

.ribbon-info.ribbon-corner.ribbon-right:before {
    border-right-color: #a927f9;
    border-left-color: transparent
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #a927f9
}

.ribbon-info.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #9106e7;
    border-right-color: #9106e7
}

.ribbon-info.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #9106e7;
    border-left-color: #9106e7
}

.ribbon-info.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: #9106e7
}

.ribbon-info.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: transparent;
    border-left-color: #9106e7
}

.ribbon-light {
    background: #f4f4f4
}

.ribbon-light {
    background: #f4f4f4
}

.ribbon-bookmark.ribbon-light:before {
    border-color: #f4f4f4;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-bottom-color: transparent
}

.ribbon-light.ribbon-corner:before {
    border-top-color: #f4f4f4;
    border-left-color: #f4f4f4
}

.ribbon-light.ribbon-corner.ribbon-right:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent
}

.ribbon-light.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f4f4f4
}

.ribbon-light.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #dbdbdb;
    border-right-color: #dbdbdb
}

.ribbon-light.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #dbdbdb;
    border-left-color: #dbdbdb
}

.ribbon-light.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: #dbdbdb
}

.ribbon-light.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: transparent;
    border-left-color: #dbdbdb
}

.ribbon-dark {
    background: #2c323f
}

.ribbon-dark {
    background: #2c323f
}

.ribbon-bookmark.ribbon-dark:before {
    border-color: #2c323f;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-bottom-color: transparent
}

.ribbon-dark.ribbon-corner:before {
    border-top-color: #2c323f;
    border-left-color: #2c323f
}

.ribbon-dark.ribbon-corner.ribbon-right:before {
    border-right-color: #2c323f;
    border-left-color: transparent
}

.ribbon-dark.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #2c323f
}

.ribbon-dark.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #171a21;
    border-right-color: #171a21
}

.ribbon-dark.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #171a21;
    border-left-color: #171a21
}

.ribbon-dark.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: #171a21
}

.ribbon-dark.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: transparent;
    border-left-color: #171a21
}

.ribbon-warning {
    background: #f8d62b
}

.ribbon-warning {
    background: #f8d62b
}

.ribbon-bookmark.ribbon-warning:before {
    border-color: #f8d62b;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-bottom-color: transparent
}

.ribbon-warning.ribbon-corner:before {
    border-top-color: #f8d62b;
    border-left-color: #f8d62b
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
    border-right-color: #f8d62b;
    border-left-color: transparent
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f8d62b
}

.ribbon-warning.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #e8c308;
    border-right-color: #e8c308
}

.ribbon-warning.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #e8c308;
    border-left-color: #e8c308
}

.ribbon-warning.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: #e8c308
}

.ribbon-warning.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: transparent;
    border-left-color: #e8c308
}

.ribbon-wrapper {
    position: relative;
    padding-top: 40px
}

.ribbon-wrapper .card-body,
.ribbon-vertical-left-wrapper .card-body,
.ribbon-vertical-right-wrapper .card-body {
    position: unset
}

.ribbon {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    clear: left;
    position: absolute;
    top: 12px;
    left: 0;
    color: #fff;
    z-index: 1
}

.ribbon-right {
    left: auto;
    right: -2px
}

.ribbon-vertical-left-wrapper {
    padding-left: 40px;
    position: relative
}

.ribbon-vertical-left {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    left: 12px;
    top: -2px
}

.ribbon-vertical-right-wrapper {
    padding-right: 40px
}

.ribbon-vertical-right {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    top: -2px;
    left: auto;
    right: 12px
}

.timeline {
    position: relative;
    padding: 20px 0;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto
}

.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #e9ecef
}

.timeline>li {
    position: relative;
    margin-bottom: 20px
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table
}

.timeline>li:after {
    clear: both
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table
}

.timeline>li:after {
    clear: both
}

.timeline>li>.timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #e9ecef;
    border-radius: .25rem;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 6px rgba(0, 0, 0, .05)
}

.timeline>li>.timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -8px;
    border-top: 8px solid transparent;
    border-right: 0 solid #e9ecef;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #e9ecef
}

.timeline>li>.timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -7px;
    border-top: 7px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #fff
}

.timeline>li>.timeline-badge {
    z-index: 1;
    position: absolute;
    top: 16px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0
}

.timeline-badge.primary {
    background-color: #fb9678
}

.timeline-badge.success {
    background-color: #00c292
}

.timeline-badge.warning {
    background-color: #fec107
}

.timeline-badge.danger {
    background-color: #e46a76
}

.timeline-badge.info {
    background-color: #03a9f3
}

.timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400
}

.timeline-body>p,
.timeline-body>ul {
    margin-bottom: 0
}

.timeline-body>p+p {
    margin-top: 5px
}

.cd-horizontal-timeline .events a {
    padding-bottom: 6px;
    color: #621aff
}

.cd-horizontal-timeline .filling-line,
.cd-horizontal-timeline .events a.selected::after {
    background: #621aff
}

.cd-horizontal-timeline .events a.selected::after {
    border-color: #621aff
}

.cd-horizontal-timeline .m-t-40 {
    margin-top: 25px !important
}

.cd-horizontal-timeline ol,
.cd-horizontal-timeline ul {
    list-style: none
}

.cd-timeline-navigation a:hover,
.cd-timeline-navigation a:focus {
    border-color: #621aff
}

.cd-horizontal-timeline a,
.cd-horizontal-timeline a:hover,
.cd-horizontal-timeline a:focus {
    color: #621aff
}

.cd-horizontal-timeline blockquote,
.cd-horizontal-timeline q {
    quotes: none
}

.cd-horizontal-timeline blockquote:before,
.cd-horizontal-timeline blockquote:after,
.cd-horizontal-timeline q:before,
.cd-horizontal-timeline q:after {
    content: '';
    content: none
}

.cd-horizontal-timeline table {
    border-collapse: collapse;
    border-spacing: 0
}

.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity .2s;
    -moz-transition: opacity .2s;
    transition: opacity .2s
}

.cd-horizontal-timeline::before {
    content: 'mobile';
    display: none
}

.cd-horizontal-timeline.loaded {
    opacity: 1
}

.cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto
}

.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px
}

.cd-horizontal-timeline .events-wrapper::before {
    left: 0
}

.cd-horizontal-timeline .events-wrapper::after {
    right: 0
}

.cd-horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 30px;
    height: 2px;
    background: #dfdfdf;
    -webkit-transition: -webkit-transform .4s;
    -moz-transition: -moz-transform .4s;
    transition: transform .4s
}

.cd-horizontal-timeline .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #621aff;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform .3s;
    -moz-transition: -moz-transform .3s;
    transition: transform .3s
}

.cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 1rem;
    padding-bottom: 15px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0)
}

.cd-horizontal-timeline .events a::after {
    content: '';
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    background-color: #f8f8f8;
    -webkit-transition: background-color .3s, border-color .3s;
    -moz-transition: background-color .3s, border-color .3s;
    transition: background-color .3s, border-color .3s
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #621aff;
    border-color: #621aff
}

.cd-horizontal-timeline .events a.selected {
    pointer-events: none
}

.cd-horizontal-timeline .events a.selected::after {
    background-color: #621aff;
    border-color: #621aff
}

.cd-horizontal-timeline .events a.older-event::after {
    border-color: #621aff
}

.cd-timeline-navigation a {
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color .3s;
    -moz-transition: border-color .3s;
    transition: border-color .3s
}

.cd-timeline-navigation a::after {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../img/arrow.svg) no-repeat 0 0
}

.cd-timeline-navigation a.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg)
}

.cd-timeline-navigation a.next {
    right: 0
}

.no-touch .cd-timeline-navigation a:hover {
    border-color: #7b9d6f
}

.cd-timeline-navigation a.inactive {
    cursor: not-allowed
}

.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px
}

.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf
}

.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    -webkit-transition: height .4s;
    -moz-transition: height .4s;
    transition: height .4s
}

.cd-horizontal-timeline .events-content li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 0 5%;
    opacity: 0;
    -webkit-animation-duration: .4s;
    -moz-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

.cd-horizontal-timeline .events-content li.selected {
    position: relative;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0)
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left
}

.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse
}

.cd-horizontal-timeline .events-content li>* {
    max-width: 800px;
    margin: 0 auto
}

.cd-horizontal-timeline .events-content h2 {
    font-weight: 600;
    margin-bottom: 0
}

.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto
}

.cd-horizontal-timeline .events-content em::before {
    content: '- '
}

.cd-horizontal-timeline .events-content p {
    font-size: 16px
}

.cd-horizontal-timeline .timeline:before {
    content: " ";
    display: none;
    bottom: 0;
    left: 0%;
    width: 0;
    margin-left: -1.5px;
    background-color: #eee
}

.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
    line-height: 30px
}

.h-250 {
    height: 250px
}

.icons-list {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.icons-list li {
    display: block;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
    border-radius: 50px;
    box-shadow: 0 2px 3px rgb(215 197 255);
    border: 1px solid #e6ebf1;
    color: #7638ff;
    margin: 5px
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    font-weight: 500
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5156be
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
    text-align: right
}

.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
    outline: 0
}

.alertify .ajs-commands {
    right: 4px;
    left: auto;
    margin: -14px 0 0 24px
}

.alertify-notifier .ajs-message {
    background-color: #5156be;
    border-color: #5156be;
    color: #fff;
    text-shadow: none !important
}

.alertify-notifier .ajs-message.ajs-success {
    background-color: #2ab57d;
    border-color: #2ab57d
}

.alertify-notifier .ajs-message.ajs-error {
    background-color: #fd625e;
    border-color: #fd625e
}

.alertify-notifier .ajs-message.ajs-warning {
    background-color: #ffbf53;
    border-color: #ffbf53
}

.alertify-notifier.ajs-right {
    right: 10px;
    left: auto
}

.alertify-notifier.ajs-right .ajs-message {
    right: -320px;
    left: auto
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 290px;
    left: auto
}

.alertify-notifier.ajs-left {
    left: 10px;
    right: auto
}

.alertify-notifier.ajs-left .ajs-message {
    left: -300px;
    right: auto
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
    left: 0;
    right: auto
}

body[data-layout-mode=dark] .alertify .ajs-dialog {
    background-color: #313533
}

body[data-layout-mode=dark] .alertify .ajs-footer,
body[data-layout-mode=dark] .alertify .ajs-header {
    color: #ced4da;
    background-color: #313533;
    border-color: #3b403d
}

body[data-layout-mode=dark] .alertify .ajs-body {
    color: #adb5bd
}

body[data-layout-mode=dark] .alertify .ajs-body .ajs-content .ajs-input {
    color: #adb5bd;
    background-color: #363a38;
    border: 1px solid #3b403d
}

body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button {
    color: #adb5bd
}

body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5156be
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    background-color: transparent;
    color: #000;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase
}

.alertify .ajs-body .ajs-content .ajs-input {
    display: block;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border-radius: 2px;
    border: 1px solid #ccc
}

.alertify .ajs-header {
    border-bottom: 1px solid #eee
}

.alertify .ajs-footer {
    border-top: 1px solid #eee
}

.swal2-actions button {
    margin-right: 10px
}

.swal2-title {
    font-size: 22px !important
}

.sticky-sidebar {
    position: fixed;
    border: 3px solid #555a64;
    top: 50%;
    background: #555a64;
    z-index: 600;
    right: 0;
    padding: 0;
    width: 50px;
    box-shadow: 0 0 15px rgb(0 0 0/12%);
    border-radius: 5px 0 0 5px;
    transform: translateY(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.sticky-sidebar.show-sidebar {
    right: 0;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s
}

.sidebar-icons {
    padding: 7px 0
}

.sticky-sidebar>li>a {
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    display: flex
}

.sticky-sidebar>li>a.feather-five {
    align-items: center;
    color: #fff;
    height: 28px;
    width: 22px;
    justify-content: center;
    text-align: center;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s
}

.sticky-sidebar>li>a:hover {
    left: 0;
    transition: .3s;
    top: 0;
    background: #fff;
    border-radius: 4px;
    color: #7638ff;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s
}

.tooltip-five {
    position: relative;
    text-align: center;
    margin: auto
}

.sticky-sidebar>li>a:hover .feather-five {
    filter: invert(1) brightness(100)
}

.tooltip-five .tooltiptext {
    visibility: hidden;
    width: 126px;
    background-color: #7638ff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 111111;
    top: 0;
    right: 41px;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize
}

.tooltip-five .tooltiptext:after {
    content: "";
    position: absolute;
    top: 49%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #7638ff
}

.tooltip-five:hover .tooltiptext {
    visibility: visible
}

.sidebar-content {
    width: 500px;
    position: absolute;
    right: 0;
    background: #fff
}

.sidebar-layout {
    width: 500px;
    height: auto;
    background-color: rgb(0, 0, 0, .2);
    position: fixed;
    right: -500px;
    top: 0;
    height: 100vh;
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s
}

.sidebar-layout.show-layout {
    right: 0;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    width: 100%
}

.sidebar-layout::-webkit-scrollbar {
    display: none
}

.sidebar-settings {
    width: 500px;
    height: auto;
    background-color: rgb(0, 0, 0, .2);
    position: fixed;
    right: -500px;
    top: 0;
    height: 100vh;
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s
}

.sidebar-settings.show-settings {
    right: 0;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    width: 100%
}

.sidebar-settings::-webkit-scrollbar {
    display: none
}

.siderbar-view {
    right: 0;
    z-index: 9999;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s
}

.siderbar-view.show-sidebar {
    right: 500px;
    transition: ease all .8s;
    -webkit-transition: ease all .8s;
    -ms-transition: ease all .8s;
    z-index: 99999
}

.sidebar-top {
    padding: 0;
    border-bottom: 1px solid #f0f1f5
}

.sidebar-logo img {
    max-height: 45px;
    width: auto
}

.btn-closed {
    position: relative;
    z-index: 1;
    color: red;
    border: 1px solid transparent;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    float: right;
    padding: 2px;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50px
}

.btn-closed:hover {
    opacity: .6
}

.sidebar-title {
    font-size: 18px;
    color: #212529;
    margin: 0 11px 15px;
    padding: 0
}

.layout {
    padding: 30px 11px;
    display: flex;
    align-items: center;
    justify-content: left
}

.layout:nth-child(2) {
    justify-content: end
}

.layout-title {
    font-size: 15px;
    color: #212529;
    margin: 0;
    text-align: center
}

.layout-link {
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: inline-block
}

.sidebar-image {
    justify-content: center;
    flex-wrap: wrap;
    display: flex
}

.sidebar-image img {
    width: 100%
}

.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    margin-right: 0
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid #6e82a5
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: #6e82a5;
    -webkit-transition: .4s;
    transition: .4s
}

.switch input:checked+.slider {
    background-color: #7638ff;
    border: 2px solid #7638ff
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #2196f3
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(20px);
    background: #fff
}

.slider.round {
    border-radius: 45px
}

.slider.round:before {
    border-radius: 50%
}

.sidebar-content .container-fluid {
    padding: 20px
}

.sidebar-but {
    padding: 6px 23px;
    background: #7638ff;
    border-radius: 5px;
    display: inline-block;
    border: 1px solid #7638ff;
    width: auto;
    margin: 20px auto;
    color: #fff;
    border: 1px solid #7638ff
}

.sidebar-but i {
    width: 18px
}

.sidebar-but .reset-icon {
    width: 18px
}

.sidebar-but span {
    transition: all .1s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
    font-size: 15px;
    line-height: 25px
}

.sidebar-but:hover {
    background: #621aff;
    color: #fff
}

.sidebar-image-one {
    padding: 0
}

.sidebar-image-one img {
    max-width: 100%
}

.sidebar-sub-title {
    font-size: 15px;
    color: #6e82a5;
    margin: 10px 0 10px 11px;
    padding: 0
}

.setting-title {
    font-size: 12px;
    color: #212529;
    margin: 0
}

.setting {
    padding: 15px 11px;
    display: flex;
    align-items: center;
    justify-content: left
}

.setting:nth-child(2) {
    justify-content: end
}

.switch-one {
    margin-right: 0;
    width: 38px;
    height: 22px
}

.slider-one:before {
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px
}

.switch input:checked+.slider-one:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(16px);
    background: #fff
}

.sidebar-color {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .11);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 10px 0
}

.sidebar-color span {
    border-radius: 1px;
    width: 75px;
    height: 75px;
    display: block;
    margin: 1px
}

.sidebar-color-one {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .11);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 53px;
    margin: 10px 0;
    display: inline-block
}

.submenu-five a {
    border-bottom: 3px solid transparent
}

.sidebar-menu-five .nav {
    padding: 0
}

.sidebar-menu-five .nav .submenu-five a {
    padding: 17px 15px
}

.submenu-five a:hover {
    border-bottom: 3px solid #7638ff
}

.sidebar-color-one span {
    border-radius: 1px;
    width: 54px;
    height: 30px;
    display: inline-block;
    margin: 0 3px
}

.color-one {
    background: #fff;
    border: 1px solid #dbdfea
}

.color-two {
    background: #f0f1f5;
    border: 1px solid #dbdfea
}

.color-three {
    background: #101924;
    border: 1px solid #dbdfea
}

.color-four {
    background: #7638ff;
    border: 1px solid #dbdfea
}

.color-five {
    background: #757575;
    border: 1px solid #dbdfea
}

.color-six {
    background: #dbdfea;
    border: 1px solid #dbdfea
}

.color-seven {
    background: #6e82a5;
    border: 1px solid #dbdfea
}

.color-eight {
    background: #757575;
    border: 1px solid #dbdfea
}

.sidebar-six {
    background-color: #fff
}

.sidebar-seven {
    background-color: #f0f1f5
}

.sidebar-six .sidebar-menu-five>ul>li>a,
.sidebar-seven .sidebar-menu-five>ul>li>a {
    color: #95979b
}

.sidebar-six .dropdown-menu-five {
    background-color: #fff
}

.sidebar-eight .dropdown-menu-five {
    background-color: #101924
}

.sidebar-seven .dropdown-menu-five {
    background-color: #f0f1f5
}

.sidebar-menu-six>ul>li>a {
    padding: 8px 15px
}

.menu-title-six {
    color: #9e9e9e
}

.sidebar-menu-six li.active>a {
    background-color: rgb(246 247 249)
}

.sidebar-menu-six li a {
    color: #6e82a5;
    display: block;
    font-size: 15px;
    height: auto
}

.sidebar-menu-six>ul>li>a:hover {
    background-color: rgb(247 248 249);
    color: #7638ff
}

.header-six {
    border-radius: 0 0 0 0;
    background-color: #fff
}

.header .header-left-six {
    background-color: #fff;
    border-bottom: 1px solid #fff
}

.header .header-left-six a {
    padding: 0 0;
    margin-left: 15px
}

.user-menu-six.nav>li>a,
.user-menu-seven.nav>li>a {
    color: #333
}

.header .has-arrow .dropdown-toggle-six:after {
    border-bottom: 2px solid #333;
    border-right: 2px solid #333
}

.header .has-arrow .dropdown-toggle-six:hover:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff
}

.header .has-arrow .dropdown-toggle-six:hover:after,
.header .has-arrow .dropdown-toggle-seven:hover:after {
    border-bottom: 2px solid #333;
    border-right: 2px solid #333
}

.sidebar-seven {
    background-color: #f0f1f5
}

.sidebar-menu-seven>ul>li>a {
    padding: 8px 15px
}

.menu-title-seven {
    color: #9e9e9e
}

.sidebar-menu-seven li.active>a {
    background-color: rgb(246 247 249)
}

.sidebar-menu-seven li a {
    color: #6e82a5;
    display: block;
    font-size: 15px;
    height: auto
}

.sidebar-menu-seven>ul>li>a:hover {
    background-color: rgb(247 248 249);
    color: #7638ff
}

.header-seven {
    border-radius: 0 0 0 0;
    background-color: #f0f1f5
}

.header .header-left-seven {
    background-color: #f0f1f5;
    border-bottom: 1px solid #f0f1f5
}

.header .header-left-seven a {
    padding: 0 0;
    margin-left: 15px
}

.user-menu-seven.nav>li>a {
    color: #333
}

.header-seven #toggle_btn {
    color: #333
}

.sidebar-eight {
    background-color: #101924
}

.sidebar-menu-eight>ul>li>a {
    padding: 8px 15px
}

.menu-title-eight {
    color: #fff
}

.sidebar-menu-eight li.active>a {
    background-color: rgb(246 247 249)
}

.sidebar-menu-eight li a {
    color: #6e82a5;
    display: block;
    font-size: 15px;
    height: auto
}

.sidebar-menu-eight>ul>li>a:hover {
    background-color: rgb(247 248 249);
    color: #7638ff
}

.header-eight {
    border-radius: 0 0 0 0;
    background-color: #101924
}

.header .header-left-eight {
    background-color: #101924;
    border-bottom: 1px solid #101924
}

.header .header-left-eight a {
    padding: 0 0;
    margin-left: 15px
}

.header .header-left-eight a img {
    max-height: 45px;
    width: 155px
}

.user-menu-eight.nav>li>a {
    color: #fff
}

.header .has-arrow .dropdown-toggle-eight:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff
}

.header .has-arrow .dropdown-toggle-eight:hover:after {
    border-bottom: 2px solid #333;
    border-right: 2px solid #333
}

.sidebar-nine {
    background-color: #757575
}

.sidebar-menu-nine>ul>li>a {
    padding: 8px 15px;
    color: #fff
}

.menu-title-nine {
    color: #fff
}

.sidebar-menu-nine li.active>a {
    background-color: rgb(246 247 249)
}

.sidebar-menu-nine li a {
    color: #fff;
    display: block;
    font-size: 15px;
    height: auto
}

.sidebar-menu-nine>ul>li>a:hover {
    background-color: rgb(247 248 249);
    color: #757575
}

.header-nine {
    border-radius: 0 0 0 0;
    background-color: #757575
}

.header .header-left-nine {
    background-color: #757575;
    border-bottom: 1px solid #757575
}

.header .header-left-nine a {
    padding: 0 0;
    margin-left: 15px
}

.user-menu-nine.nav>li>a {
    color: #fff
}

.header .has-arrow .dropdown-toggle-nine:after {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff
}

.header .has-arrow .dropdown-toggle-nine:hover:after {
    border-bottom: 2px solid #333;
    border-right: 2px solid #333
}

.sidebar-menu-ten li.active>a::before {
    background: #7638ff
}

.sidebar-menu-ten li.active>a {
    background-color: rgb(117 117 117);
    color: #dbdfea
}

.sidebar-menu-eleven li.active>a::before {
    background: #f0f1f5
}

.sidebar-menu-eleven li.active>a {
    background-color: rgb(117 117 117);
    color: #dbdfea
}

.sidebar-menu-twelve li.active>a::before {
    background: #7638ff
}

.sidebar-menu-twelve li.active>a {
    background-color: rgb(16 25 36);
    color: #6e82a5
}

.white-logo {
    display: none;
    line-height: 60px
}

.white-logo.show-logo {
    display: block
}

.header .header-left .logo.hide-logo {
    display: none
}

.mini-sidebar .white-logo.show-logo {
    display: none
}

.invoices-links {
    color: #1b2559;
    margin-right: 20px
}

.invoices-links:last-child {
    margin-right: 0
}

.invoices-links:hover {
    color: #7638ff
}

.invoices-links.active {
    color: #7638ff
}

.invoices-links .feather {
    width: 25px;
    height: 25px
}

.report-card {
    border-radius: 5px
}

.app-listing {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start
}

.app-listing li {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px
}

.multipleSelection .selectbox,
.sortby .selectboxes {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 13px 10px;
    font-weight: 400;
    background: #fff;
    border: 1px solid #f7f8f9;
    border-radius: 7px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    cursor: pointer
}

.selectbox-cont {
    height: 220px;
    overflow-y: auto;
    padding-right: 5px
}

.selectbox-cont-one {
    overflow-y: inherit
}

.selectbox-cont::-webkit-scrollbar {
    width: 4px;
    background: #eef1fe;
    height: 10px
}

.selectbox-cont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e7e7e7;
    border-radius: 4px
}

.selectbox-cont::-webkit-scrollbar-thumb {
    background: rgba(231, 234, 252, .75);
    border-radius: 4px
}

.selectbox-cont::-webkit-scrollbar-thumb:hover {
    background: rgba(231, 234, 252, .75)
}

.selectboxes.order-by {
    min-width: 154px;
    background: #eef1fe;
    padding: 6px 10px
}

.multipleSelection {
    position: relative;
    margin-bottom: 20px
}

.multi-filter,
.sortby {
    position: relative
}

.multi-filter:before {
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0/10%);
    content: "";
    right: 0;
    position: absolute;
    top: 2px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.multipleSelection #checkboxes,
.sortby #checkbox {
    display: none;
    position: absolute;
    width: 300px;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0 4px 44px rgba(231, 234, 252, .75);
    border-radius: 6px
}

.multipleSelection #checkboxes-one {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2ecff;
    box-shadow: 0 4px 44px rgba(231, 234, 252, .75);
    border-radius: 6px
}

.multipleSelection #checkboxes-one::before {
    border: 7px solid #f2ecff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0/10%);
    content: "";
    left: 30px;
    position: absolute;
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

#checkboxes-one .form-custom {
    margin-bottom: 14px
}

#checkboxes-one .form-custom .form-control {
    padding-left: 14px;
    border: 1px solid rgba(231, 234, 252, .75);
    border-radius: 6px
}

#checkboxes-one .btn-grey:hover {
    background: #1b2559;
    color: #fff
}

#checkboxes-one .btn-grey {
    background: rgba(231, 234, 252, .75);
    color: #1b2559;
    margin-top: 10px
}

#checkboxes-one .btn,
#checkbox .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0
}

.multipleSelection #checkboxes::before,
.sortby #checkbox::before {
    border: 7px solid #f2ecff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0/10%);
    content: "";
    left: 30px;
    position: absolute;
    top: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.check-list {
    position: relative
}

.date-picker {
    width: 50%;
    float: left;
    padding-right: 10px
}

#checkboxes .date-picker .bootstrap-datetimepicker-widget table td {
    padding-left: 10px;
    padding-right: 10px
}

#checkboxes .date-picker .form-custom .form-control {
    padding-left: 10px
}

.date-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.date-list ul li {
    width: 100%;
    padding: 0;
    padding-bottom: 5px
}

.date-list ul li:last-child {
    padding-bottom: 0
}

.date-list .date-btn {
    background: #fff;
    color: #1b2559;
    border: 1px solid #e7eafc;
    border-radius: 4px;
    padding: 10px 0 !important;
    width: 100%
}

.date-list .date-btn:hover {
    background: #7638ff;
    color: #fff;
    border: 1px solid #e7eafc
}

.form-custom {
    position: relative
}

.form-custom.cal-icon:after {
    font-size: 14px
}

.form-custom i {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #7e84a3
}

#checkboxes .form-custom {
    margin-bottom: 14px
}

.card-header .form-custom .form-control {
    padding-left: 30px;
    height: 35px
}

#checkboxes .form-custom .form-control {
    padding-left: 14px;
    border: 1px solid rgba(231, 234, 252, .75);
    border-radius: 6px
}

#checkboxes .form-custom .form-control::placeholder {
    color: #8f9bba
}

.multipleSelection #checkboxes.form-custom .form-control {
    padding-left: 25px;
    background: #f5f6fa;
    border: 1px solid #e6e9f4;
    border-radius: 5px;
    height: 33px
}

.multipleSelection #checkboxes .form-custom i {
    position: absolute;
    top: 35%;
    left: 6px;
    color: #7e84a3;
    font-size: 12px
}

#checkboxes .btn,
#checkbox .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 0
}

#checkboxes .btn-grey {
    background: rgba(231, 234, 252, .75);
    color: #1b2559;
    margin-top: 10px
}

#checkboxes .btn-grey:hover {
    background: #1b2559;
    color: #fff
}

.checkbox-title {
    font-weight: 600;
    font-size: 16px;
    color: #7638ff;
    margin-bottom: 14px
}

.custom_check {
    color: #1b2559;
    display: inline-block;
    position: relative;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.custom_check input:checked~.checkmark {
    background-color: #7638ff;
    border-color: #7638ff
}

.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 21px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 6px;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "font awesome 5 free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    color: #fff;
    font-size: 9px
}

.custom_check input:checked~.checkmark:after {
    display: block
}

.selectbox p {
    color: #1b2559;
    font-weight: 500
}

.selectbox p .select-icon {
    color: #7638ff;
    width: 20px;
    height: 20px
}

.report-btn {
    margin-bottom: 20px
}

.report-btn .btn {
    font-weight: 600;
    color: #7638ff;
    background: #fff;
    border: 2px solid #7638ff;
    border-radius: 8px;
    width: 100%;
    padding: 11px 0
}

.report-btn .btn:hover {
    color: #fff;
    background: #7638ff;
    border: 2px solid #7638ff
}

.report-btn .btn:hover img {
    filter: invert(0) brightness(100)
}

.invoices-tabs-card {
    background: 0 0;
    box-shadow: unset
}

.invoices-main-tabs {
    border-bottom: 2px solid #f3f3f3;
    padding-bottom: 10px
}

.invoices-tabs ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.invoices-tabs ul li {
    position: relative;
    display: inline-block;
    margin-right: 36px
}

.invoices-tabs ul li:last-child {
    margin-right: 0
}

.invoices-tabs ul li a {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    padding-bottom: 24px
}

.invoices-tabs ul li a:hover {
    color: #7638ff
}

.invoices-tabs ul li a.active {
    color: #7638ff;
    border-bottom: 2px solid #7638ff
}

.invoices-settings-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end
}

.invoices-settings-icon {
    color: #7638ff;
    margin-right: 20px
}

.invoices-settings-icon .feather {
    width: 24px;
    height: 24px
}

.invoices-settings-btn .btn {
    font-weight: 600;
    color: #fff;
    padding: 12px 10px;
    min-width: 167px;
    background: #1b2559;
    border: 1px solid #1b2559;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.invoices-settings-btn .btn:hover {
    background: #7638ff;
    border: 1px solid #7638ff
}

.invoices-settings-btn .btn .feather {
    margin-right: 6px
}

.inovices-card {
    border-radius: 5px
}

.inovices-card .card-body {
    padding: 15px
}

.inovices-widget-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.inovices-widget-icon img {
    width: 50px
}

.inovices-widget-header .inovices-amount {
    font-weight: 600;
    font-size: 24px;
    color: #7638ff
}

.inovices-all {
    font-weight: 600;
    color: #1b2559;
    margin-top: 16px
}

.inovices-all span {
    font-size: 10px;
    margin-left: 15px
}

.invoices-grid-card {
    background: #fff;
    box-shadow: 0 4px 24px rgba(228, 228, 228, .25);
    border-radius: 10px;
    color: #1b2559
}

.invoices-grid-card .card-header {
    padding: 20px;
    border: 0
}

.invoices-grid-card .card-header .dropdown-action {
    margin-bottom: 0
}

.invoices-grid-card .card-header .action-icon {
    color: #1b2559;
    display: initial
}

.invoices-grid-card .card-body {
    padding: 20px
}

.invoices-grid-card .card-body span {
    font-size: 14px
}

.invoices-grid-card .card-body h6 {
    font-size: 16px;
    font-weight: 500;
    color: #1b2559;
    margin-top: 4px
}

.invoice-grid-link {
    color: #1b2559
}

.invoice-grid-link:hover {
    color: #7638ff
}

.card-middle {
    padding: 20px;
    background: #f7f7ff
}

.card-middle-avatar {
    align-items: center;
    display: inline-flex;
    font-size: 16px;
    margin: 0
}

.card-middle-avatar a {
    color: #1b2559
}

.card-middle-avatar a:hover {
    color: #7638ff
}

.invoices-grid-card .card-footer {
    padding: 20px;
    border: 0
}

.invoices-grid-card .card-footer .badge {
    border-radius: 6px;
    font-size: 13px;
    font-weight: 400;
    padding: 7px 18px
}

.invoices-grid-card .bg-success-dark {
    background: #008f64
}

.invoices-grid-card .bg-danger-dark {
    background: red
}

.invoices-grid-card .bg-secondary-dark {
    background: #7638ff
}

.invoices-grid-card .bg-primary-dark {
    background: #2196f3
}

.invoices-grid-card .text-sm {
    font-size: 14px
}

.invoice-load-btn {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px
}

.invoice-load-btn .btn {
    font-weight: 700;
    font-size: 16px;
    color: #3e45eb;
    min-width: 170px;
    padding: 12px 15px;
    background: #fff;
    border: 2px solid #3e45eb;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center
}

.invoice-load-btn .btn:hover {
    color: #fff;
    background: #3e45eb;
    border: 2px solid #3e45eb
}

.invoice-load-btn .btn span {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 8px;
    vertical-align: 0;
    border: 3px solid #3e45eb7d;
    border-right-color: #3e45eb;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border
}

.invoice-load-btn .btn:hover span {
    border: 3px solid #ffffff7a;
    border-right-color: #fff
}

.invoices-page-header {
    background: #fff;
    border-radius: 10px;
    padding: 12px
}

.invoices-page-header .invoices-breadcrumb-item a {
    font-weight: 600;
    color: #7638ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.invoices-page-header .invoices-breadcrumb-item a:hover {
    color: #377dff
}

.invoices-page-header .invoices-breadcrumb-item i {
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: #7638ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.invoices-page-header .invoices-breadcrumb-item a:hover i {
    color: #fff;
    background: #377dff
}

.invoices-create-btn .btn {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border-radius: 8px;
    padding: 10px 0;
    min-width: 160px
}

.invoices-create-btn .invoices-preview-link {
    font-weight: 600;
    color: #7638ff;
    margin-right: 30px
}

.invoices-create-btn .invoices-preview-link:hover {
    color: #377dff
}

.invoices-create-btn .delete-invoice-btn {
    background: #1b2559;
    margin-right: 10px
}

.invoices-create-btn .delete-invoice-btn:hover {
    background: #7638ff
}

.invoices-create-btn .save-invoice-btn {
    background: #7638ff
}

.invoices-create-btn .save-invoice-btn:hover {
    background: #1b2559
}

.invoices-add-card {
    background: #fff;
    border-radius: 10px
}

.invoices-main-form {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e5e5e5
}

.invoices-form label {
    font-weight: 600;
    color: #1b2559
}

.invoices-form .multipleSelection .selectbox {
    border: 2px solid #7638ff;
    border-radius: 6px
}

.invoices-form .form-control {
    height: 50px;
    border: 1px solid #e5e5e5;
    border-radius: 6px
}

.invoices-form .form-control::placeholder {
    color: #8f9bba;
    font-weight: 400
}

.invoice-details-title {
    font-weight: 500;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: .5rem
}

.invoice-details-box {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px
}

.invoice-inner-head {
    padding: 26px;
    border-bottom: 1px solid #e5e5e5
}

.invoice-inner-head span {
    color: #1b2559
}

.invoice-inner-head span a {
    font-weight: 500;
    color: #7638ff
}

.invoice-inner-head span a:hover {
    font-weight: 500;
    color: #377dff
}

.invoice-inner-footer {
    padding: 0
}

.invoice-inner-date {
    padding: 26px;
    border-right: 1px solid #e5e5e5
}

.invoice-inner-datepic {
    border: 0;
    padding-left: 0
}

.invoice-inner-footer span {
    font-weight: 500;
    color: #1b2559;
    position: relative
}

.invoice-inner-footer .form-control {
    position: absolute;
    color: #7638ff;
    background: 0 0;
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0 10px
}

.invoice-inner-footer .form-control::placeholder {
    color: #7638ff;
    font-weight: 500
}

.inovices-month-info {
    padding-top: 50px
}

.inovices-month-info label {
    font-weight: 400
}

.inovices-month-info .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #8f9bba;
    border-radius: 6px
}

.inovices-month-info .form-control {
    height: 42px
}

#show-invoices {
    display: none
}

.invoice-add-table {
    padding-top: 20px;
    padding-bottom: 30px
}

.invoice-add-table h4 {
    font-weight: 600;
    font-size: 20px;
    color: #7638ff;
    margin-bottom: 30px
}

.invoice-add-table th {
    font-size: 16px;
    color: #1b2559
}

.invoice-add-table .no-border td {
    border: 0
}

.invoice-add-table .table-form-control td .form-control::placeholder {
    color: #1b2559
}

.invoice-add-table .add-product {
    border-bottom: 1px solid #e5e5e5
}

.invoice-add-table .add-product td {
    border: 0
}

.invoice-add-table .add-product td a {
    color: #7638ff
}

.invoice-add-table th {
    background: #f8f9fa
}

.invoice-add-table td,
.invoice-add-table th {
    border-color: #e5e5e5;
    padding: 20px
}

.invoice-add-table .add-btn {
    color: #7638ff
}

.invoice-add-table .remove-btn {
    color: red
}

.invoice-add-table .copy-btn {
    color: #c4c4c4
}

.invoice-fields .field-title {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 15px
}

.field-box {
    padding: 15px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px
}

.field-box p {
    font-weight: 500;
    font-size: 14px;
    color: #1b2559;
    margin-bottom: 10px
}

.field-box .btn {
    min-width: 266px;
    border-radius: 4px;
    padding: 30px 0
}

.payment-details {
    width: 266px;
    border-radius: 4px;
    padding: 15px;
    background: #7638ff;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.payment-details p {
    color: #fff;
    max-width: 140px;
    font-weight: 400;
    margin-bottom: 0
}

.payment-details a {
    font-size: 26px;
    color: #fff
}

.invoice-faq .faq-tab {
    padding-top: 10px
}

.faq-tab .panel-title {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 0;
    position: relative
}

.faq-tab .panel-title a {
    color: #c4c4c4
}

.faq-tab .panel-title a:hover {
    color: #1b2559
}

.faq-tab .panel-title a:not(.collapsed) {
    color: #1b2559
}

.faq-tab .panel-title a.collapsed::after {
    content: '\f067';
    font-family: 'font awesome 5 free';
    position: absolute;
    right: 20px;
    top: 10px;
    color: #f09e9e;
    font-weight: 600;
    font-size: 14px
}

.faq-tab .panel-title a::after {
    content: '\f068';
    font-family: 'font awesome 5 free';
    position: absolute;
    right: 20px;
    top: 10px;
    color: #f09e9e;
    font-weight: 600;
    font-size: 14px
}

.faq-tab .panel-title a:not(.collapsed)::after {
    color: red
}

.faq-tab .panel-body {
    padding: 10px 20px;
    border: 1px solid #e5e5e5
}

.faq-tab .panel-body textarea {
    min-height: 100px;
    border: 0;
    border-radius: 0 0 4px 4px
}

.invoice-total-card .invoice-total-title {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 15px
}

.invoice-total-box {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px
}

.invoice-total-inner {
    padding: 15px
}

.invoice-total-box p {
    color: #1b2559;
    margin-bottom: 20px;
    position: relative
}

.invoice-total-box p span {
    float: right
}

.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.checktoggle {
    background-color: #c4c4c4;
    border: 1px solid #c4c4c4;
    border-radius: 12px;
    cursor: pointer;
    font-size: 0;
    height: 15px;
    margin-left: 10px;
    top: 3px;
    margin-bottom: 0;
    position: absolute;
    width: 30px;
    display: inline-block
}

.checktoggle:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease
}

.check:checked+.checktoggle {
    background-color: #7638ff;
    border: 1px solid #7638ff
}

.check:checked+.checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%)
}

.onoffswitch {
    margin-left: auto;
    position: relative;
    width: 73px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none
}

.onoffswitch-checkbox {
    display: none
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 0
}

.onoffswitch-inner {
    display: block;
    margin-left: -100%;
    transition: margin .3s ease-in 0s;
    width: 200%
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%
}

.onoffswitch-inner:before {
    background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right
}

.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
    height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all .3s ease-in 0s;
    width: 20px
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0
}

.invoice-total-box .add-links {
    font-weight: 500;
    color: #7638ff;
    margin-bottom: 20px;
    display: block
}

.invoice-total-box .service-amount {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.invoice-total-box .service-trash {
    margin-bottom: 10px;
    color: #1b2559
}

.invoice-total-box .service-trash i {
    color: #ec1361
}

.invoice-total-box .add-links-one {
    font-weight: 500;
    color: #7638ff
}

.invoice-total-box .service-trash-one {
    margin-top: 10px;
    color: #1b2559
}

.invoice-total-box .service-trash-one i {
    color: #ec1361
}

.invoice-total-footer {
    border-top: 1px solid #e5e5e5;
    padding: 15px
}

.invoice-total-footer h4 {
    font-size: 20px;
    margin-bottom: 0;
    color: #7638ff
}

.invoice-total-footer h4 span {
    float: right
}

.upload-sign {
    float: right;
    margin-top: 20px
}

.service-upload {
    border: 2px dashed #e5e5e5;
    text-align: center;
    padding: 30px 0;
    background-color: #fff;
    position: relative;
    width: 330px
}

.service-upload span {
    font-size: 15px;
    color: #858585;
    display: block
}

.service-upload input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.upload-sign .btn {
    border-radius: 8px;
    min-width: 198px;
    padding: 10px 0
}

.invoice-info-card {
    border-radius: 14px
}

.invoice-item-one {
    border-bottom: 1px solid rgba(231, 234, 252, .75)
}

.invoice-item-one .invoice-info {
    margin-bottom: 30px;
    text-align: right
}

.customer-text-one {
    font-size: 20px;
    color: #1b2559;
    font-weight: 600;
    display: block;
    margin-bottom: 10px
}

.invoice-name {
    color: #1b2559;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px
}

.invoice-item-one .invoice-details {
    color: #1b2559;
    font-weight: 400
}

.invoice-head h2 {
    font-weight: 600;
    font-size: 28px;
    color: #1b2559;
    text-transform: uppercase;
    margin-bottom: 0
}

.invoice-head {
    margin-bottom: 30px
}

.invoice-head p {
    font-weight: 400;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 0
}

.invoice-item-two {
    padding-top: 30px;
    padding-bottom: 30px
}

.invoice-item-two .invoice-details {
    color: #1b2559;
    font-weight: 400
}

.invoice-item-box {
    background: #f7f7ff;
    border-radius: 5px;
    padding: 15px;
    width: 200px;
    float: right;
    margin-top: 20px
}

.invoice-item-box p {
    color: #1b2559;
    font-weight: 400;
    margin-bottom: 10px
}

.invoice-issues-box {
    background: #7638ff;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    margin-bottom: 30px
}

.invoice-issues-date {
    padding-top: 20px;
    padding-bottom: 20px
}

.invoice-issues-date p {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0
}

.invoice-table-wrap {
    margin-bottom: 30px
}

.invoice-table>thead>tr>th {
    background: #f5f7ff;
    border-top: 0;
    font-weight: 500;
    font-size: 16px;
    color: #1b2559
}

.invoice-table>tbody>tr>td {
    font-weight: 400;
    color: #1b2559;
    padding: 15px 20px
}

.invoice-terms {
    margin-bottom: 30px
}

.invoice-terms h6 {
    font-weight: 500;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 6px
}

.invoice-terms p {
    font-weight: 400;
    font-size: 16px;
    color: #8f9bba
}

.invoice-sign {
    padding-top: 30px
}

.invoice-sign span {
    color: #1b2559;
    margin-top: 18px
}

.invoice-item-bg {
    background: #7638ff;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px
}

.invoice-circle-img {
    position: relative
}

.invoice-circle-img .invoice-circle1 {
    position: absolute;
    left: 0;
    opacity: .1
}

.invoice-circle-img .invoice-circle2 {
    position: absolute;
    right: 0;
    opacity: .1
}

.invoice-item-bg .invoice-info {
    border-right: 1px solid #fff;
    padding: 30px;
    margin-top: 20px
}

.invoice-item-bg .customer-text-one {
    color: #fff
}

.invoice-item-bg .invoice-name {
    color: #fff
}

.invoice-item-bg .invoice-details-two {
    color: #fff
}

.invoice-item-bg .invoice-info-one p {
    color: #fff;
    margin-bottom: 10px
}

.invoice-sign-box {
    margin-top: 30px
}

.invoice-payment-box h4 {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    margin-bottom: 16px
}

.invoice-payment-box .payment-details {
    background: 0 0;
    border: 2px dashed #7638ff
}

.invoice-payment-box .payment-details p {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559
}

.invoices-preview .modal-body {
    padding: 0
}

.invoices-settings-card {
    border-radius: 5px
}

.invoices-settings-card .card-title {
    color: #1b2559
}

.invoices-settings-form label {
    color: #1b2559
}

.invoices-settings-form .form-control {
    height: 50px;
    border-radius: 6px
}

.invoices-upload-btn {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    color: #000;
    cursor: pointer;
    font-weight: 400;
    padding: 15px;
    position: relative;
    width: 100%;
    height: 50px
}

.invoices-upload-btn .hide-input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-height: auto;
    padding-left: 4px;
    padding-top: 0;
    line-height: 10px;
    width: 100%;
    opacity: 0
}

.invoices-upload-btn .upload {
    position: absolute;
    background: #7638ff;
    border-radius: 4px;
    right: 6px;
    top: 8px;
    width: 136px;
    height: 32px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff
}

.invoice-setting-btn .btn {
    min-width: 160px;
    padding: 10px 0;
    color: #fff;
    border-radius: 8px
}

.invoice-setting-btn .cancel-btn {
    background: #1b2559
}

.invoice-setting-btn .cancel-btn:hover {
    background: #7638ff
}

.invoice-setting-btn .btn-primary:hover {
    background: #1b2559;
    border: 1px solid #1b2559
}

.invoices-items-main-tabs {
    border-bottom: 3px solid #f3f3f3;
    padding-bottom: 10px
}

.invoices-items-tabs ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.invoices-items-tabs ul li {
    display: inline-block;
    padding-left: 36px;
    padding-right: 36px;
    position: relative
}

.invoices-items-tabs ul li:last-child {
    padding-right: 0
}

.invoices-items-tabs ul li a {
    font-weight: 600;
    font-size: 16px;
    color: #1b2559;
    padding-bottom: 24px;
    position: relative
}

.invoices-items-tabs ul li a.active:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 4px;
    width: 130px;
    height: 6px;
    background-color: #7638ff;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%)
}

.invoices-items-tabs ul li a.active {
    color: #7638ff
}

.invoices-items-tabs ul li a:hover {
    color: #7638ff
}

.circle-btn {
    width: 33px;
    height: 33px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center
}

.blog {
    background: #fff;
    border-radius: 18px;
    margin-bottom: 25px;
    padding: 15px;
    position: relative
}

.grid-blog .blog-image {
    margin-bottom: 20px;
    border-radius: 10px
}

.blog-image,
.blog-image>a,
.blog-image img {
    display: block;
    position: relative;
    width: 100%;
    height: auto
}

.blog-image img {
    border-radius: 10px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms
}

.blog-content {
    position: relative;
    margin-bottom: 10px
}

.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: 0
}

.grid-blog .entry-meta li {
    margin-bottom: 15px
}

.entry-meta li {
    display: inline-block;
    margin-right: 15px
}

.grid-blog .post-author {
    width: 189px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.back-btn {
    font-weight: 600;
    font-size: 16px;
    color: #7638ff;
    display: inline-block;
    margin-bottom: 18px
}

.post-author span:nth-child(1):hover {
    color: #7638ff
}

.post-author a {
    display: flex;
    display: -ms-flexbox;
    align-items: center
}

.post-author img {
    border-radius: 5px;
    width: 42px;
    height: 42px;
    margin-right: 10px
}

.grid-blog .blog-title {
    font-size: 16px;
    margin: 0 0 6px
}

.grid-blog .blog-title a {
    color: #333;
    transition: all .5s;
    font-weight: 600;
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden
}

.grid-blog .blog-content p {
    font-size: 14px;
    margin: 0 0 15px;
    color: #71717a
}

.post-title {
    display: block;
    color: #131523;
    font-weight: 600
}

.post-date {
    font-size: 12px;
    color: #7e84a3
}

.blog-views {
    position: absolute;
    top: 20px;
    left: 15px;
    background: #f5f6fa;
    border-radius: 6px;
    padding: 2px 5px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    font-size: 12px
}

.blog-catagories {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #fc8543;
    border-radius: 6px;
    min-width: 92px;
    padding: 6px 10px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center
}

.blog-catagories p {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0
}

.blog-views img {
    width: auto;
    margin-right: 5px
}

.edit-delete-btn a {
    margin-right: 6px;
    font-size: 12px;
    border-radius: 5px;
    padding: 6px 13px
}

.edit-delete-btn a:nth-child(1) {
    margin-right: 6px;
    background: rgba(23, 208, 83, .21)
}

.edit-delete-btn a:nth-child(2) {
    background: rgba(255, 0, 0, .1)
}

.btn-blog {
    padding: 10px 22px
}

.edit-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.active-text {
    color: #1b5a90
}

.modal.contentmodal .modal-content .submit-section .btn.btn-download {
    min-width: 200px
}

.list-links {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    padding: 0
}

.list-links li {
    margin: 0 15px 10px 0;
    list-style: none
}

.list-links li a {
    background: #fff;
    border-radius: 5px;
    padding: 12px 30px;
    font-size: 14px;
    color: #131523;
    border: 1px solid #f5f6fa;
    display: inline-block
}

.inactive-style {
    font-size: 14px
}

.list-links li a:hover {
    background: #e5e5e8
}

.list-links li a span {
    font-size: 14px
}

.list-links li.active a {
    background: #fff;
    border: 1px solid #7638ff;
    color: #7638ff
}

.grid-blog .blog-title a:hover {
    color: #7638ff
}

.select-by .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 4px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg)
}

.modal-select-box .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 4px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 45%;
    width: 0;
    transform: rotate(224deg);
    -webkit-transform: rotate(224deg)
}

.select-by .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -2px
}

.pagination-tab {
    padding: 30px
}

.header.fixed-header .user-menu.nav>li.view-btn>a {
    background-color: #fff;
    color: #000
}

.header.fixed-header .user-menu.nav>li.view-btn>a:hover {
    background-color: #d19eb7;
    color: #fff
}

.modal-select-box .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #131523;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -2px
}

.status-toggle {
    display: flex;
    align-items: center
}

.status-toggle span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px
}

.close-btn {
    border: 0;
    outline: 0;
    background: 0 0;
    font-size: 18px
}

.blog-single-post .blog-image {
    margin-bottom: 20px;
    border-radius: 25px
}

.blog-single-post .blog-title {
    font-weight: 700;
    color: #000;
    margin-bottom: 25px;
    font-size: 20px
}

.about-author-img {
    background-color: #fff;
    height: 62px;
    width: 62px
}

.about-author-img img {
    border-radius: 5px;
    height: 62px;
    width: 62px
}

.author-details {
    margin-left: 20px;
    flex: 1
}

.about-author {
    display: flex;
    display: -ms-flexbox;
    align-items: center
}

.author-details .blog-author-name {
    display: inline-block;
    color: #131523;
    font-weight: 600;
    margin-bottom: 5px
}

.author-details .blog-author-name span {
    font-size: 12px;
    font-weight: 400;
    color: #7e84a3;
    padding-left: 3px
}

.author-details p {
    font-size: 14px
}

.blog-comments .comments-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
}

.blog-comments .comments-list li {
    clear: both;
    padding-left: 57px
}

.blog-comments .comments-list li .comment {
    margin-bottom: 20px
}

.blog-comments .comments-list li .comment-author {
    left: 0;
    position: absolute
}

.blog-comments .comments-list li img.avatar {
    height: 42px;
    width: 42px;
    border-radius: 5px
}

.blog-comments .comment-btn {
    color: #000;
    display: inline-block;
    font-size: 12px
}

.blog-comments .comment-btn img {
    width: auto !important
}

.blog-author-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px
}

.blog-date {
    color: #7e84a3;
    font-size: 12px;
    padding-left: 15px;
    font-weight: 400
}

.comments-list .comment-block p {
    font-size: 14px;
    margin-bottom: 10px
}

.new-comment .form-floating>.form-control,
.form-floating>.form-select {
    height: 50px
}

.new-comment .form-floating>label {
    padding: .8rem .6rem
}

.social-share {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0
}

.social-share>li {
    display: inline-block;
    float: left;
    margin-left: 10px;
    text-align: center
}

.social-share>li:first-child {
    margin-left: 0
}

.social-share>li>a {
    border: 1px solid #7638ff;
    border-radius: 6px;
    color: #7638ff;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 40px;
    width: 40px
}

.social-share>li>a:hover {
    background-color: #7638ff;
    color: #fff;
    border-color: #fff
}

.post-list ul {
    margin: 0 0 20px;
    padding-left: 0;
    list-style: none;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    flex-wrap: wrap
}

.post-list ul li {
    font-size: 13px;
    color: #7e84a3;
    margin-bottom: 5px;
    margin-right: 30px
}

.post-list ul li i {
    padding-right: 5px
}

.post-list ul li:last-child {
    margin-right: 0
}

.post-author img {
    border-radius: 5px;
    width: 36px;
    height: 36px;
    margin-right: 10px
}

.post-author span:nth-child(1) {
    font-size: 14px;
    color: #131523;
    transition: all .5s
}

.post-author span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    color: #7e84a3;
    transition: all .5s
}

.post-list .post-author a span {
    font-size: 13px;
    font-weight: 600;
    color: #7e84a3;
    transition: all .5s
}

.post-list .post-author a span:hover {
    color: #7638ff
}

.blog-content p {
    font-size: 15px
}

.back-btn:hover {
    color: #000
}

a.blog-author-name:hover {
    color: #7638ff
}

a.comment-btn:hover {
    color: #7638ff
}

.save-btn {
    font-weight: 600;
    font-size: 16px;
    padding: 6px 75px
}

.upload-file {
    margin-bottom: 20px
}

.upload-file h6 {
    font-weight: 400;
    margin-bottom: 15px
}

.upload-file img {
    border-radius: 5px;
    margin-bottom: 6px
}

.upload-file p {
    font-size: 12px;
    color: #7e84a3
}

.up-img {
    position: relative;
    padding-left: 11px
}

.up-img .close-icon {
    position: absolute;
    top: -9px
}

.table-action-btn {
    width: 79px;
    height: 27px
}

.bank-details .modal-footer.blog-categories-btn {
    justify-content: start
}

.blog-categories-btn {
    padding: 20px
}

.modal-footer.blog-categories-btn>.bank-details-btn .btn {
    border-radius: 4px
}

.status-toggle .checktoggle {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px
}

.status-toggle .checktoggle {
    top: 0
}

.status-toggle .checktoggle:after {
    height: 15px;
    width: 15px
}

.status-toggle .check:checked+.checktoggle.checkbox-bg::after {
    background-color: #fff
}

.status-toggle .checktoggle.checkbox-bg::after {
    background: rgba(0, 0, 0, .25)
}

.bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    box-shadow: unset;
    display: block;
    min-height: 44px;
    padding: 6px 6px 0
}

.bootstrap-tagsinput .bg-info,
.bootstrap-tagsinput .badge-info {
    background-color: #7638ff !important;
    color: #fff
}

.bootstrap-tagsinput .tag [data-role=remove] {
    margin-left: 8px;
    cursor: pointer
}

.bootstrap-tagsinput .tag [data-role=remove]:after {
    content: "x";
    padding: 0 2px
}

.bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit
}

.bootstrap-tagsinput .badge {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    padding: 10px 15px
}

.bank-inner-details .checktoggle.checkbox-bg {
    margin-left: 0
}

.categories-table .checktoggle.checkbox-bg {
    margin-left: 0
}

.categories-table .pagination {
    display: none
}

.categories-table .dataTables_info {
    display: none
}

.categories-table tr:last-child td {
    padding-bottom: 0;
    border: none
}

.blog-view .card-header {
    padding: 15px
}

.blog-image:hover img {
    transform: scale(1.1)
}

.blog-image {
    overflow: hidden
}

.del-icon {
    margin-bottom: 23px
}

.del-icon i {
    font-size: 60px;
    color: #f0142f;
    margin-bottom: 23px
}

.modal-content .close-btn i {
    font-size: 20px;
    color: #f0142f
}

.modal.contentmodal .modal-content .submit-section .btn {
    padding: 7px 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    min-width: 150px;
    margin-top: 5px
}

.change-photo-btn {
    color: #7e84a3;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    padding: 15px;
    position: relative;
    transition: .3s;
    text-align: center;
    height: 120px;
    background: #fafafa;
    border: 1px dashed #e1e1e1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px
}

.change-photo-btn input.upload {
    bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100px;
    width: 100%
}

.bootstrap-tagsinput .tag [data-role=remove] {
    margin-left: 8px;
    cursor: pointer
}

.bootstrap-tagsinput .tag [data-role=remove]:after {
    content: "x";
    padding: 0 2px
}

.bank-inner-details label {
    margin-bottom: 10px
}

#DataTables_Table_0_wrapper .table.custom-table>tbody>tr>td,
.table.custom-table>thead>tr>th {
    white-space: nowrap
}

#DataTables_Table_0 .table-action-btn {
    height: auto
}

.bootstrap-tagsinput .bg-info,
.bootstrap-tagsinput .badge-info {
    background-color: #7638ff !important;
    color: #fff
}

.page-title-right {
    margin-bottom: 15px
}

.navtab-bg .nav-item {
    margin: 0 5px
}

.navtab-bg .nav-link {
    background-color: #edeff1;
    color: #455560
}

.nav-pills .nav-link {
    color: #455560
}

.nav-bordered {
    border-bottom: 2px solid #dee2e6
}

.nav-bordered li a {
    border: 0 !important
}

.nav-bordered a.active {
    border-bottom: 2px solid #0d6efd !important
}

.nav-bordered .nav-item {
    margin-bottom: -1px
}

.nav-tabs.nav-bordered .nav-link:focus,
.nav-tabs.nav-bordered .nav-link:hover {
    background-color: transparent
}

.button-list .btn:first-child {
    margin-left: 0
}

.grid-structure .grid-container {
    background-color: #dee2e6;
    margin-bottom: 10px;
    padding: 10px 20px
}

.accordion-faq {
    font-size: 18px
}

.custom-faq .card-header:first-child {
    border-radius: 20px
}

.custom-accordion .card-header:first-child {
    border-radius: 20px
}

.breadcrumb-line .breadcrumb-item:before {
    content: "-"
}

.breadcrumb-line .breadcrumb-item:first-child:before {
    content: ""
}

.breadcrumb-dot .breadcrumb-item:before {
    content: "•"
}

.breadcrumb-dot .breadcrumb-item:first-child:before {
    content: ""
}

.breadcrumb-separatorless .breadcrumb-item:before {
    content: ""
}

.breadcrumb-colored {
    padding: 12px 16px;
    border-radius: 4px
}

.breadcrumb-colored li a {
    color: #fff
}

.breadcrumb-colored .breadcrumb-item.active {
    color: #fff;
    opacity: .8
}

.breadcrumb-colored .breadcrumb-item+.breadcrumb-item::before {
    color: #fff
}

.twitter-bs-wizard-pager-link {
    display: inline-block
}

@media(min-width:768px) {
    .avatar-xxl {
        width: 8rem;
        height: 8rem
    }

    .avatar-xxl .border {
        border-width: 4px !important
    }

    .avatar-xxl .rounded {
        border-radius: 12px !important
    }

    .avatar-xxl .avatar-title {
        font-size: 42px
    }

    .avatar-xxl.avatar-away:before,
    .avatar-xxl.avatar-offline:before,
    .avatar-xxl.avatar-online:before {
        border-width: 4px
    }
}

@media(min-width:992px) {
    #toggle_btn {
        align-items: center;
        color: #333;
        display: inline-flex;
        float: left;
        font-size: 30px;
        height: 60px;
        justify-content: center;
        margin-left: 15px;
        padding: 0 15px
    }

    .header-four #toggle_btn {
        color: #fff
    }

    .header .header-left-two #toggle_btn {
        padding: 0 0;
        margin-left: 0
    }

    .thead-warning{
        background-color: #A0CA53 !important;
    }
    .btn-light{
        background-color: #E3E3E3 !important;
        color: #298456 !important;
    }
    .btn-light:hover{
        background-color: #E3E3E3 !important;
        color: #000 !important;
    }
    
    .active3{
        border: 2px solid #298456 !important
    }


    .mini-sidebar .header-left .logo img {
        height: auto;
        max-height: 40px;
        width: auto
    }

    .mini-sidebar .header .header-left .logo {
        display: none
    }

    .mini-sidebar .header-left .logo.logo-small {
        display: block
    }

    .mini-sidebar .header .header-left {
        padding: 0 5px;
        width: 78px
    }

    .mini-sidebar .sidebar {
        width: 78px
    }

    .mini-sidebar.expand-menu .sidebar {
        width: 240px
    }

    .mini-sidebar .menu-title {
        visibility: hidden;
        white-space: nowrap
    }

    .mini-sidebar.expand-menu .menu-title {
        visibility: visible
    }

    .mini-sidebar .menu-title a {
        visibility: hidden
    }

    .mini-sidebar.expand-menu .menu-title a {
        visibility: visible
    }

    .modal-open.mini-sidebar .sidebar {
        z-index: 1051
    }

    .mini-sidebar .sidebar .sidebar-menu ul>li>a span {
        display: none;
        transition: all .2s ease-in-out;
        opacity: 0
    }

    .mini-sidebar.expand-menu .sidebar .sidebar-menu ul>li>a span {
        display: inline;
        opacity: 1
    }

    .mini-sidebar .page-wrapper {
        margin-left: 78px
    }

    .mini-sidebar .header-two:before {
        margin-left: 78px
    }

    .mini-sidebar .page-wrapper-four {
        margin-left: 0
    }

    .sidebar-hide {
        display: none
    }

    .header-two #toggle_btn {
        color: #fff
    }

    .header-six #toggle_btn {
        color: #333
    }

    .header-seven #toggle_btn {
        color: #333
    }

    .header-eight #toggle_btn {
        color: #fff
    }

    .header-nine #toggle_btn {
        color: #fff
    }
}

@media(max-width:1199.98px) {
    .app-listing {
        display: inline-block
    }

    .app-listing li {
        width: 49%;
        display: inline-block
    }

    .invoices-tabs ul li {
        margin-right: 14px
    }

    .invoices-tabs ul li a {
        font-size: 15px
    }

    .report-btn .btn {
        width: 200px
    }

    .inovices-month-info {
        padding-top: 0
    }
}

@media(max-width:991.98px) {
    .header .header-left {
        position: absolute;
        width: 30%
    }

    .add-item {
        margin-top: 10px
    }

    .flag-nav {
        display: none
    }

    .mobile_btn {
        color: #333;
        cursor: pointer;
        display: block;
        font-size: 24px;
        height: 60px;
        left: 185px;
        line-height: 60px;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        top: 0;
        z-index: 10
    }

    #toggle_btn {
        display: none
    }

    .top-nav-search {
        display: none
    }

    .login-wrapper .loginbox .login-left {
        padding: 80px 50px;
        width: 50%
    }

    .login-wrapper .loginbox .login-right {
        padding: 50px;
        width: 50%
    }

    .sidebar {
        margin-left: -225px;
        width: 225px;
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        transition: all .4s ease;
        z-index: 1041
    }

    .page-wrapper {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        transition: all .4s ease
    }

    .chat-window .chat-scroll {
        max-height: calc(100vh - 255px)
    }

    .chat-window .chat-cont-left,
    .chat-window .chat-cont-right {
        flex: 0 0 100%;
        max-width: 100%;
        transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
        width: 100%
    }

    .chat-window .chat-cont-left {
        border-right: 0
    }

    .chat-window .chat-cont-right {
        position: absolute;
        right: calc(-100% + -1.875rem);
        top: 0
    }

    .chat-window .chat-cont-right .chat-header {
        justify-content: start
    }

    .chat-window .chat-cont-right .chat-header .back-user-list {
        display: block
    }

    .chat-window .chat-cont-right .chat-header .chat-options {
        margin-left: auto
    }

    .chat-window.chat-slide .chat-cont-left {
        left: calc(-100% + -1.875rem)
    }

    .chat-window.chat-slide .chat-cont-right {
        right: 0
    }

    .mobile_btn-two {
        color: #fff
    }

    .user-menu-two.nav>li>a {
        color: #fff
    }

    .user-menu-two.nav>li>a i {
        color: #fff
    }

    .header .has-arrow .dropdown-toggle-two:after {
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff
    }

    .header .has-arrow .dropdown-toggle-two:hover:after {
        border-bottom: 2px solid #333;
        border-right: 2px solid #333
    }

    .header .header-left-two {
        border-radius: 0 0 0 0
    }

    .page-wrapper-three {
        margin-left: 0;
        padding-top: 60px;
        position: relative;
        transition: all .4s ease
    }

    .sidebar-three {
        width: 260px;
        margin-left: -265px
    }

    .sidebar-three-three {
        margin-left: -265px;
        left: 0
    }

    .header .header-left-four {
        position: absolute;
        width: unset
    }

    .sidebar-five {
        display: none
    }

    .user-menu-five {
        display: none !important
    }

    .header .header-left-three {
        background: 0 0
    }

    .header-two .mobile_btn {
        color: #fff
    }

    .header-six .mobile_btn {
        color: #333
    }

    .header-seven .mobile_btn {
        color: #333
    }

    .header-eight .mobile_btn {
        color: #fff
    }

    .header-nine .mobile_btn {
        color: #fff
    }

    .layout,
    .setting {
        flex: 1 0 0%
    }

    .invoice-item-bg {
        padding: 20px;
        padding-bottom: 0
    }

    .invoice-circle-img {
        display: none
    }

    .invoice-item-bg .invoice-info {
        border-right: 0;
        padding: 0;
        margin-top: 0
    }

    .multipleSelection #checkboxes,
    .sortby #checkbox {
        width: 100%
    }

    .invoice-head h2 {
        font-size: 18px
    }

    .invoice-head p {
        font-size: 16px
    }

    .invoices-preview .modal-dialog {
        max-width: 700px
    }

    .invoice-terms h6 {
        font-size: 18px
    }

    .invoice-total-footer h4 {
        font-size: 15px
    }

    .invoice-payment-box .payment-details p {
        font-size: 15px
    }
}

@media(max-width:767.98px) {
    body {
        font-size: 14px
    }

    h1,
    .h1 {
        font-size: 2rem
    }

    h2,
    .h2 {
        font-size: 1.75rem
    }

    h3,
    .h3 {
        font-size: 1.5rem
    }

    h4,
    .h4 {
        font-size: 1.125rem
    }

    h5,
    .h5 {
        font-size: 1rem
    }

    h6,
    .h6 {
        font-size: .975rem
    }

    div.dataTables_wrapper div.dataTables_filter {
        margin-top: 10px
    }

    .header .has-arrow .dropdown-toggle:after {
        display: none
    }

    .user-menu.nav>li>a>span:not(.user-img) {
        display: none
    }

    .navbar-nav .open .dropdown-menu {
        float: left;
        position: absolute
    }

    .navbar-nav.user-menu .open .dropdown-menu {
        left: auto;
        right: 0
    }

    .header .header-left {
        padding: 0 15px;
        width: 30%;
    }
    .mobile_btn {
        color: #333;
        cursor: pointer;
        display: block;
        font-size: 24px;
        height: 60px;
        left: 60px;
        line-height: 60px;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        top: 0;
        z-index: 10
    }

    .header .header-left .logo {
        display: none
    }

    .header-left .logo.logo-small {
        display: inline-block
    }

    .login-wrapper .loginbox .login-left {
        display: none
    }

    .login-wrapper .loginbox {
        max-width: 450px;
        min-height: unset
    }

    .login-wrapper .loginbox .login-right {
        float: none;
        padding: 1.875rem;
        width: 100%
    }

    .top-action-left .float-left {
        float: none !important
    }

    .top-action-left .btn-group {
        margin-bottom: 15px
    }

    .top-action-right {
        text-align: center
    }

    .top-action-right a.btn.btn-white {
        margin-bottom: 15px
    }

    .mail-sent-time {
        float: left;
        margin-top: 10px;
        width: 100%
    }

    .login-wrapper .loginbox .login-right h1 {
        font-size: 22px
    }

    .error-box h1 {
        font-size: 6em
    }

    .error-box .btn {
        font-size: 15px;
        min-width: 150px;
        padding: 8px 20px
    }

    .dash-count {
        font-size: 16px;
        display: inline-block
    }

    .dash-widget-header {
        display: block
    }

    .nav {
        display: block
    }

    .timeline>li>.timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0
    }

    ul.timeline>li>.timeline-panel {
        float: right;
        width: calc(100% - 90px)
    }

    .timeline>li>.timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0
    }

    ul.timeline>li>.timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0
    }

    ul.timeline:before {
        left: 40px
    }

    .header .header-left-two .logo img,
    .header .header-left-two .dark-logo img {
        max-height: 45px;
        width: auto
    }

    .ui-aside-compact .ui-aside {
        width: 67px
    }

    .sidebar-content {
        width: auto
    }

    .siderbar-view.show-sidebar {
        z-index: 999
    }

    .btn-closed {
        margin-top: -35px
    }

    .app-listing li {
        width: 100%
    }

    .invoices-tabs ul li {
        margin-right: 0;
        display: block;
        margin-bottom: 14px
    }

    .invoices-tabs ul li:last-child {
        margin-bottom: 0
    }

    .invoices-tabs ul li a {
        padding-bottom: 6px
    }

    .invoices-settings-btn {
        margin-top: 20px;
        justify-content: start
    }

    .invoices-settings-btn-one {
        margin-top: 0
    }

    .invoices-settings-btn .btn {
        min-width: 140px;
        padding: 8px 10px;
        font-size: 14px
    }

    .inovices-widget-header .inovices-amount {
        font-size: 20px
    }

    .report-btn .btn {
        font-size: 14px;
        padding: 10px 0
    }

    .invoices-grid-card .card-header,
    .card-middle,
    .invoices-grid-card .card-body,
    .invoices-grid-card .card-footer {
        padding: 15px
    }

    .invoice-load-btn {
        margin-top: 0
    }

    .invoice-load-btn .btn {
        font-size: 16px;
        min-width: 150px;
        padding: 6px 0
    }

    .invoice-load-btn .btn span {
        width: 20px;
        height: 20px;
        margin-right: 6px
    }

    .form-header h3 {
        font-size: 18px
    }

    .form-header h4 {
        font-size: 18px
    }

    .paid-continue-btn {
        font-size: 14px;
        padding: 7px 20px
    }

    .paid-cancel-btn {
        font-size: 14px;
        padding: 7px 20px
    }

    .invoice-details-title {
        font-size: 16px
    }

    .invoices-page-header .invoices-breadcrumb-item a {
        font-size: 14px
    }

    .invoices-create-btn .invoices-preview-link {
        margin-right: 0
    }

    .invoices-create-btn .btn {
        display: none
    }

    .invoice-inner-head {
        padding: 15px
    }

    .invoice-inner-date {
        padding: 15px;
        padding-bottom: 0
    }

    .invoice-inner-datepic {
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 15px
    }

    .inovices-month-info {
        padding-top: 20px
    }

    .invoice-add-table th {
        font-size: 14px
    }

    .invoice-item .invoice-details-two {
        margin-bottom: 20px;
        font-size: 14px
    }

    .invoice-add-table {
        padding-top: 10px
    }

    .invoice-add-table h4 {
        font-size: 16px;
        margin-bottom: 20px
    }

    .invoice-item .customer-text {
        font-size: 16px
    }

    .field-box .btn {
        min-width: 160px;
        padding: 8px 0;
        font-size: 14px
    }

    .invoice-faq {
        margin-bottom: 20px
    }

    .faq-tab .panel-title a {
        font-size: 14px
    }

    .faq-tab .panel-title a::after {
        content: '\f068';
        font-family: 'font awesome 5 free';
        font-weight: 600
    }

    .faq-tab .panel-title a.collapsed::after {
        content: '\f067';
        font-family: 'font awesome 5 free';
        font-weight: 600
    }

    .invoice-total-footer h4 {
        font-size: 14px
    }

    .upload-sign {
        width: 100%
    }

    .service-upload {
        width: 100%
    }

    .upload-sign .btn {
        min-width: 140px;
        padding: 8px 0;
        font-size: 14px
    }

    .payment-details {
        width: auto
    }

    .bank-details .modal-header {
        padding: 15px
    }

    .bank-details .modal-body {
        padding: 15px;
        padding-bottom: 0
    }

    .bank-details .modal-footer {
        padding: 15px
    }

    .bank-details .close {
        top: 6px
    }

    .bank-details .bank-inner-details label {
        font-size: 14px
    }

    .bank-details-btn .btn {
        min-width: 120px;
        padding: 7px 0;
        font-size: 14px
    }

    .invoice-issues-box {
        padding-bottom: 20px
    }

    .invoice-issues-date {
        padding-top: 10px;
        padding-bottom: 0
    }

    .invoice-issues-date p {
        font-size: 14px
    }

    .invoice-head h2 {
        font-size: 16px
    }

    .invoice-head p {
        font-size: 14px
    }

    .invoice-item-one .invoice-info {
        text-align: left
    }

    .customer-text-one {
        font-size: 16px
    }

    .invoice-name {
        font-size: 14px
    }

    .invoice-item-one .invoice-details {
        text-align: left
    }

    .invoice-item-two .invoice-info2 {
        text-align: left
    }

    .invoice-item-two .invoice-details {
        text-align: left
    }

    .invoice-item-box {
        width: 100%;
        float: left
    }

    .invoice-item-box p {
        margin-bottom: 6px
    }

    .invoice-terms h6 {
        font-size: 15px
    }

    .invoice-terms p {
        font-size: 14px
    }

    .invoice-payment-box .payment-details {
        margin-bottom: 20px
    }

    .invoice-payment-box .payment-details p {
        font-size: 14px
    }

    .invoice-setting-btn .btn {
        min-width: 118px;
        padding: 7px 0;
        font-size: 14px
    }

    .invoices-preview .modal-dialog {
        max-width: 500px
    }

    .invoice-payment-box h4 {
        font-size: 15px
    }

    .profile-cover-avatar {
        margin: -3rem auto .5rem
    }
}

@media(max-width:575.98px) {
    .card {
        margin-bottom: .9375rem
    }

    .page-wrapper>.content {
        padding: .9375rem .9375rem 0
    }

    .chat-window {
        margin-bottom: .9375rem
    }

    .card-body {
        padding: 1.25rem
    }

    .card-header {
        padding: .75rem 1.25rem
    }

    .card-footer {
        padding: .75rem 1.25rem
    }

    .page-header {
        margin-bottom: .9375rem
    }

    .pagination-lg .page-link {
        font-size: 1.2rem;
        padding: .5rem .625rem
    }

    .invoice-details {
        float: left;
        text-align: left
    }

    .fc-toolbar .fc-right {
        display: inline-block;
        float: none;
        margin: 10px auto 0;
        width: 200px;
        clear: both
    }

    .fc-toolbar .fc-left {
        float: none;
        margin: 0 auto;
        width: 200px
    }

    .fc-toolbar .fc-center {
        display: inline-block;
        width: 100%;
        text-align: center
    }

    .fc-toolbar .fc-center h2 {
        width: 100%
    }

    .chat-window .chat-cont-right .chat-header .media .media-body {
        display: none
    }

    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
    .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
        max-width: 160px
    }

    .page-header .breadcrumb {
        display: none
    }

    .page-header .invoices-breadcrumb {
        display: inline-block
    }

    .sticky-sidebar li a {
        font-size: 20px;
        height: 44px;
        width: 44px
    }

    .white-logo.show-logo {
        display: none
    }

    .logo-small {
        display: block
    }

    .invoice-item-one .invoice-info {
        margin-bottom: 20px
    }

    .status-toggle .checktoggle:after {
        height: 10px;
        width: 10px
    }

    .status-toggle .checktoggle {
        height: 18px;
        width: 35px
    }

    .list-links li {
        margin: 0 0 10px 5px
    }

    .del-icon {
        margin-bottom: 10px
    }

    .submit-section {
        margin-top: 16px
    }

    .delete-wrap h2 {
        font-size: 20px
    }

    .modal.contentmodal .modal-content .submit-section .btn {
        min-width: 127px
    }

    .del-icon i {
        font-size: 50px
    }

    .about-author-img {
        margin-bottom: 10px
    }

    .about-author {
        flex-direction: column
    }

    p:last-child {
        margin-bottom: 20px
    }

    .btn .btn-primary .waves-light {
        margin-bottom: 20px
    }
}


/* Slim modern scrollbar */
.sidebar-inner {
    overflow-y: hidden; /* Allow vertical scroll */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #ccc transparent; /* For Firefox */
    height: 100%;
    
}
.sidebar:hover .sidebar-inner {
    overflow-y: auto;  /* Show scrollbar on hover */
    background: transparent;  
}
  
  /* Track of the scrollbar */
.sidebar-inner::-webkit-scrollbar-track {
    background: transparent; /* Transparent track */
  }
  
  /* Handle of the scrollbar (thumb) */
  .sidebar-inner::-webkit-scrollbar-thumb {
    background-color: rgb(204, 204, 204); /* Set the scrollbar thumb color */
    border-radius: 10px; /* Make it rounded */
  }
  /* For Chrome, Edge, and Safari */
  .sidebar-inner::-webkit-scrollbar {
    width: 7px;
  }
/*   
  .sidebar-inner::-webkit-scrollbar-track {
    background: transparent;
  }
   */
  /* .sidebar-inner::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  } */
/*   
  .sidebar-inner::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  } */

  .bg-ligter {
    background-color: #E3E3E3;
    padding: 12px;
    border-radius: 8px;
  }

  .card-title2 {
    margin-bottom: 0;
    color: #298456;
    font-size: large;
}
  .breadcrumb2 {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: .25rem;
}
  
  
.bg-success-dark {
    background: #008f64 !important;
}

.bg-table {
    background-color: #EFEFEF;
    border-radius: 8px;
}

.border-bottom-newest {
    border-bottom: 3px solid #A0CA53 !important;
}

