.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide{
  display: none !important;
}
.show{
  display: block !important;
}




.right-sidebar {
	background-color: #1F2937;
	border-right: 1px solid #1F2937;
	bottom: 0;
	right: 0;
	margin-top: 0;
	position: fixed;
	top: 60px;
	transition: all 0.2s ease-in-out 0s;
	width: 500px;
	z-index: 1001;
  }
.right-sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.right-sidebar-inner {
	height: 100%;
	transition: all 0.2s ease-in-out 0s;
	/* background: rgb(204, 204, 204); */
    /* width: 7px; */
    /* position: absolute; */
    /* top: 0px;  */
    /* opacity: 1;
    display: block;
    border-radius: 7px;
    z-index: 99;
    right: 1px;
    height: 75.9041px; */
}
.right-sidebar-inner {
	/* height: 100%; */
	/* transition: all 0.2s ease-in-out 0s; */
	/* background: rgb(204, 204, 204); */
    /* width: 7px; */
    /* position: absolute; */
    /* top: 0px;  */
    /* opacity: 1;
    display: block;
    border-radius: 7px;
    z-index: 99;
    right: 1px;
    height: 75.9041px; */
} 
.slimScrollRail {
	height: 100%;
	transition: all 0.2s ease-in-out 0s;
	/* background: rgb(204, 204, 204); */
    /* width: 7px; yeh pooora code hila rha hai*/
    /* position: absolute; yeh pora code hila rha ha */
    top: 0px;
    opacity: 1;
    display: block;
    border-radius: 7px;
    z-index: 99;
    right: 1px;
    /* height: 75.9041px; yeh code ko chalna nhi derha */

	/* position: "relative"; */
	/* overflow: "show"; */
	/* width: "100%"; */
	/* height: "421px"; */
}

.right-sidebar-menu {
	padding: 10px 0;
}
.right-sidebar-menu ul {
	font-size: 15px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.right-sidebar-menu li a {
	color: #fff;
	display: block;
	font-size: 14px;
	height: auto;
	padding: 0 20px;
}	
.right-sidebar-menu li a:hover {
	background-color: #111827;
}
.right-sidebar-menu li.active a {
	color: #333;
	background-color: #f3f2fa;
}
